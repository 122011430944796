import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Box, CssBaseline } from "@mui/material";
import MainAppBar from "../AppBar/AppBar";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

export default function MainComponent() {
  const { user } = useAuth();

  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const StyledBox = styled(Box)({
    display: "flex",
    // flexDirection: "column",
    minHeight: `${height - 410}px`,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <MainAppBar user={user} />
      <StyledBox>
        <CssBaseline />
        <Main>
          <Outlet />
        </Main>
      </StyledBox>
      <Footer />
    </Box>
  );
}
