import React, { useEffect, useRef, useState } from "react";
import ImageAnnotationTool from "../../AnnotationTool/ImgAnnotator";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Pagination,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { DroneImage } from "../../../utils/interfaces";
import { useLocation } from "react-router-dom";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import { BACKEND_APP_URL } from "../../../constants";
import { useAuth } from "../../../hooks/useAuth";
import { ToastContainer } from "react-toastify";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IMAGE_TYPE = {
  UNANNOTATED: "Unannotated",
  ANNOTATED: "Annotated",
};

const ManagerCreateAnnotations = () => {
  const location = useLocation();
  const imageCountPerPage = 3;

  const [imageUrl, setImageUrl] = useState<string>("");
  const auth = useAuth();
  const user = auth?.user;

  const [open, setOpen] = useState(false);
  const [dronImages, setDronImages] = useState<DroneImage[]>([]);
  const [filteredDroneImages, setFilteredDroneImages] = useState<DroneImage[]>(
    []
  );
  const [selectedDroneImages, setSelectedDroneImages] = useState<DroneImage[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [imageType, setImageType] = useState<string>(IMAGE_TYPE.UNANNOTATED);
  const [sendingImgRequest, setSendingImgRequest] = useState(false);
  const [imageAnnotated, setImageAnnotated] = useState(false);

  const counter = useRef(0);
  const imageLoaded = () => {
    counter.current = counter.current + 1;

    if (counter.current >= selectedDroneImages.length) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setSendingImgRequest(true);
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/business-manager/drone-operator-images/${location.state.church._id}`,
      "GET",
      null,
      user,
      auth.logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          setDronImages(response.data);
          if (imageType === IMAGE_TYPE.UNANNOTATED) {
            setFilteredDroneImages(
              response.data.filter((image: DroneImage) => !image.isAnnotated)
            );
            setSelectedDroneImages(
              response.data
                .filter((image: DroneImage) => !image.isAnnotated)
                .slice(0, imageCountPerPage)
            );
          } else {
            setFilteredDroneImages(
              response.data.filter((image: DroneImage) => image.isAnnotated)
            );
            setSelectedDroneImages(
              response.data
                .filter((image: DroneImage) => image.isAnnotated)
                .slice(0, imageCountPerPage)
            );
          }
          setSendingImgRequest(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching review requests", error);
        setSendingImgRequest(false);
      });
  }, [imageAnnotated]);

  const handleImageTypeChange = (imgType: string) => {
    switch (imgType) {
      case IMAGE_TYPE.UNANNOTATED:
        setImageType(IMAGE_TYPE.UNANNOTATED);
        setFilteredDroneImages(
          dronImages.filter((image) => !image.isAnnotated)
        );
        break;
      case IMAGE_TYPE.ANNOTATED:
        setImageType(IMAGE_TYPE.ANNOTATED);
        setFilteredDroneImages(dronImages.filter((image) => image.isAnnotated));
        break;
    }
  };

  useEffect(() => {
    const startIndex = 0;
    const endIndex = imageCountPerPage;
    setSelectedDroneImages(filteredDroneImages.slice(startIndex, endIndex));
  }, [filteredDroneImages]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    const startIndex = (value - 1) * imageCountPerPage;
    const endIndex = value * imageCountPerPage;
    setSelectedDroneImages(filteredDroneImages.slice(startIndex, endIndex));
  };

  return (
    <>
      <ToastContainer />

      <ButtonGroup
        disabled={sendingImgRequest}
        sx={{ m: 1 }}
        variant="outlined"
        aria-label="Basic button group"
      >
        <Button
          variant={
            imageType == IMAGE_TYPE.UNANNOTATED ? "contained" : "outlined"
          }
          onClick={() => {
            handleImageTypeChange(IMAGE_TYPE.UNANNOTATED);
          }}
        >
          To Be Analyzed
        </Button>
        <Button
          variant={imageType == IMAGE_TYPE.ANNOTATED ? "contained" : "outlined"}
          onClick={() => {
            handleImageTypeChange(IMAGE_TYPE.ANNOTATED);
          }}
        >
          Analyzed
        </Button>
      </ButtonGroup>

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "row",
            gap: 1,
          }}
        >
          {sendingImgRequest && <CircularProgress color="secondary" />}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "row",
            gap: 1,
          }}
        >
          {!sendingImgRequest && filteredDroneImages.length === 0 && (
            <Typography variant="h5">No Images</Typography>
          )}
        </Box>
        <ImageList variant="masonry" cols={3} gap={10}>
          {selectedDroneImages.map((image, index) => {
            return (
              <ImageListItem key={index}>
                <div
                  style={{
                    display: loading ? "flex" : "none",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "300px",
                  }}
                >
                  <CircularProgress color="secondary" />
                </div>
                <img
                  srcSet={`${image.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${image.url}?w=248&fit=crop&auto=format`}
                  alt={image.name}
                  style={{ display: loading ? "none" : "inline" }}
                  onLoad={() => {
                    imageLoaded();
                  }}
                />
                <IconButton
                  onClick={() => {
                    setImageAnnotated(false);
                    setImageUrl(image.url);
                    setOpen(true);
                  }}
                  sx={{
                    backgroundColor: "#ff9800",
                    "&:hover": {
                      backgroundColor: "#ffa726",
                    },
                  }}
                  style={{
                    display: loading ? "none" : "inline",
                    borderRadius: "10px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  Analyze
                </IconButton>
                <ImageListItemBar title={image.name} />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignContent={"center"}
        flexDirection={"row"}
      >
        {!sendingImgRequest && filteredDroneImages.length !== 0 && (
          <Pagination
            count={Math.ceil(filteredDroneImages.length / imageCountPerPage)}
            color="secondary"
            onChange={handlePageChange}
          />
        )}
      </Box>

      <Dialog
        sx={{ mt: 8 }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ fontSize: 24 }}>Comment on the image</DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <ImageAnnotationTool
            setImageAnnotated={setImageAnnotated}
            imageUrl={imageUrl}
            requestId={location.state.church._id}
            handleModelClose={handleClose}
            editAccess={true}
          />
        </Grid>
      </Dialog>
    </>
  );
};

export default ManagerCreateAnnotations;
