import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Button } from "@mui/material";

interface ConfirmDeleteDialogProps {
  open: boolean;
  onClose: (confirm: boolean) => void;
}

export default function ConfirmDeleteDialog(props: ConfirmDeleteDialogProps) {
  const { open, onClose } = props;

  return (
    <Dialog
          open={open}
          onClose={() => { onClose(false) }} // Disagree
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Maintenance Engineer?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting a maintenance engineer will remove all the data associated with the engineer. This action cannot be undone.
              Do you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(false)}>Cancel</Button>
            <Button onClick={() => onClose(true)} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
  );
}