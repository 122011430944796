import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Pagination,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { DroneImage } from "../../utils/interfaces";
import { performRequestWithRetry } from "../../utils/apiRetry";
import { BACKEND_APP_URL } from "../../constants";
import ImageAnnotationTool from "../AnnotationTool/ImgAnnotator";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IMAGE_TYPE = {
  UNANNOTATED: "Unannotated",
  ANNOTATED: "Annotated",
};

const CommentsComponent = () => {
  const imageCountPerPage = 2;
  const intialStart = 0;
  const intialEnd = imageCountPerPage;

  const [imageUrl, setImageUrl] = useState<string>("");
  const auth = useAuth();
  const user = auth?.user;
  const logout = auth.logout;

  const [open, setOpen] = useState(false);
  const [dronImages, setDronImages] = useState<DroneImage[]>([]);
  const [selectedImages, setSelectedImages] = useState<DroneImage[]>([]);
  const [imagesForPage, setImagesForPage] = useState<DroneImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [imageType, setImageType] = useState<string>(IMAGE_TYPE.ANNOTATED);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(imageCountPerPage);
  const [sendingImgRequest, setSendingImgRequest] = useState(false);
  const [assignedReqId, setAssignedReqId] = useState<string>("");

  const counter = useRef(0);
  const imageLoaded = () => {
    counter.current = counter.current + 1;

    if (counter.current >= imagesForPage.length) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setSendingImgRequest(true);
    // set assigned request id
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/church-manager/requests/assigned/${user?.id}`,
      "GET",
      null,
      user,
      logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          setAssignedReqId(response.data[0]._id);
          // fetch drone images for the assigned request
          performRequestWithRetry(
            `${BACKEND_APP_URL}/api/church-manager/comments/${response.data[0]._id}`,
            "GET",
            null,
            user,
            logout
          )
            .then((response) => {
              if (response && response.status === 200) {
                setDronImages(response.data);
                setSelectedImages(
                  response.data.filter((image: DroneImage) => image.isAnnotated)
                );
                setSendingImgRequest(false);
              }
            })
            .catch((error) => {
              console.error("Error fetching drone images", error);
              setSendingImgRequest(false);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching assigned request", error);
        setSendingImgRequest(false);
      });
  }, []);

  useEffect(() => {
    setImagesForPage(selectedImages.slice(start, end));
  }, [start, end, selectedImages]);

  const handleImageTypeChange = (imgType: string) => {
    switch (imgType) {
      case IMAGE_TYPE.UNANNOTATED:
        setImageType(IMAGE_TYPE.UNANNOTATED);
        setSelectedImages(dronImages.filter((image) => !image.isAnnotated));
        break;
      case IMAGE_TYPE.ANNOTATED:
        setImageType(IMAGE_TYPE.ANNOTATED);
        setSelectedImages(dronImages.filter((image) => image.isAnnotated));
        break;
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setStart(intialStart + (value - 1) * imageCountPerPage);
    setEnd(intialEnd + (value - 1) * imageCountPerPage);
  };

  return (
    <>
      <ToastContainer />

      {/* <ButtonGroup
        sx={{ m: 1 }}
        variant="outlined"
        aria-label="Basic button group"
      >
        <Button
          variant={imageType == IMAGE_TYPE.ANNOTATED ? "contained" : "outlined"}
          onClick={() => {
            handleImageTypeChange(IMAGE_TYPE.ANNOTATED);
          }}
        >
          Analyzed
        </Button>
        <Button
          variant={
            imageType == IMAGE_TYPE.UNANNOTATED ? "contained" : "outlined"
          }
          onClick={() => {
            handleImageTypeChange(IMAGE_TYPE.UNANNOTATED);
          }}
        >
          To Be Analyzed
        </Button>
      </ButtonGroup> */}

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "row",
            gap: 1,
          }}
        >
          {sendingImgRequest && <CircularProgress color="secondary" />}
        </Box>
        <ImageList variant="masonry" cols={3} gap={10}>
          {imagesForPage.map((image, index) => {
            return (
              <ImageListItem key={index}>
                <div
                  style={{
                    display: loading ? "flex" : "none",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "300px",
                  }}
                >
                  <CircularProgress color="secondary" />
                </div>
                <img
                  srcSet={`${image.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${image.url}?w=248&fit=crop&auto=format`}
                  alt={image.name}
                  style={{ display: loading ? "none" : "inline" }}
                  onLoad={() => {
                    imageLoaded();
                  }}
                />
                <IconButton
                  onClick={() => {
                    setImageUrl(image.url);
                    setOpen(true);
                  }}
                  sx={{
                    backgroundColor: "#3f51b5",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#757de8",
                    },
                  }}
                  style={{
                    display: loading ? "none" : "inline",
                    borderRadius: "10px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  Open Analytics
                </IconButton>
                <ImageListItemBar title={image.name} />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignContent={"center"}
        flexDirection={"row"}
      >
        <Pagination
          count={Math.ceil(selectedImages.length / imageCountPerPage)}
          color="secondary"
          onChange={handlePageChange}
        />
      </Box>

      <Dialog
        sx={{ mt: 8 }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Typography variant="h3" sx={{ pl: 2 }}>
            Check Analytics
          </Typography>
        </DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <ImageAnnotationTool
            setImageAnnotated={null}
            imageUrl={imageUrl}
            requestId={assignedReqId}
            handleModelClose={handleClose}
            editAccess={false}
          />
        </Grid>
      </Dialog>
    </>
  );
};

export default CommentsComponent;
