import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import { CircularProgress, Grid } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { performRequestWithRetry } from "../../utils/apiRetry";
import { BACKEND_APP_URL } from "../../constants";
import { toast } from "react-toastify";
import { AnnotationDescription } from "./AnnotationDescription";
import { AnnotationDialogBox } from "./AnnotationDialogBox";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export interface Annotation {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  topic: string;
  description: string;
}

interface ChildProps {
  imageUrl: string;
  requestId: string;
  setImageAnnotated: ((value: boolean) => void) | null;
  handleModelClose: () => void;
  editAccess: boolean;
}

const ImageAnnotationTool: React.FC<ChildProps> = ({
  imageUrl,
  requestId,
  setImageAnnotated,
  handleModelClose,
  editAccess,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [color, setColor] = useState<string>("#eb9234");
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [currentAnnotation, setCurrentAnnotation] = useState<Annotation | null>(
    null
  );
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [startY, setStartY] = useState<number>(0);
  const [currentX, setCurrentX] = useState<number>(0);
  const [currentY, setCurrentY] = useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(false);

  const auth = useAuth();
  const user = auth.user;
  const logout = auth.logout;

  const fetchAnnotations = () => {
    const imageName = imageUrl.split("/").pop();
    if (!editAccess) {
      performRequestWithRetry(
        `${BACKEND_APP_URL}/api/church-manager/comments/${requestId}/${imageName}`,
        "GET",
        null,
        user,
        logout
      )
        .then((response) => {
          if (response && response.status === 200) {
            if (response.data.length > 0) {
              setAnnotations(response.data[0].annotations);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching annotations", error);
        });
      setLoading(false);
    } else {
      performRequestWithRetry(
        `${BACKEND_APP_URL}/api/business-manager/comments/${requestId}/${imageName}`,
        "GET",
        null,
        user,
        logout
      )
        .then((response) => {
          if (response && response.status === 200) {
            if (response.data.length > 0) {
              setAnnotations(response.data[0].annotations);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching annotations", error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);

    const loadImage = () => {
      const img = new Image();
      img.onload = () => {
        setImage(img);
      };
      img.src = imageUrl;
    };
    fetchAnnotations();
    if (imageUrl) {
      loadImage();
    }
  }, []);

  // Clear only the region affected by the annotation
  const handleSave = () => {
    const data = {
      request_id: requestId,
      annotations: annotations,
      imageUrl: imageUrl,
      created_by: user.id,
    };
    // call save api
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/business-manager/comments`,
      "POST",
      data,
      user,
      logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          if (!setImageAnnotated) return;
          setImageAnnotated(true);
          handleClose();
          toast.success("Annotations saved successfully"); // toast does not show up
          handleModelClose();
        }
      })
      .catch((error) => {
        console.error("Error saving annotations", error);
        toast.error("Error saving annotations");
      });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!ctx || !image) return;
    if (!canvas) return;

    // Set canvas size to full width of the page
    canvas.width = window.innerWidth * 0.98;
    canvas.height = (image.height / image.width) * window.innerWidth;

    // Clear the canvas before drawing
    ctx.clearRect(0, 0, canvas.width * zoom, canvas.height * zoom);

    // Draw image onto the canvas
    ctx.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      offset.x,
      offset.y,
      canvas.width * zoom,
      canvas.height * zoom
    );

    // Draw existing annotations
    annotations.forEach((annotation) => {
      ctx.strokeStyle = annotation.color;
      ctx.lineWidth = 2;
      ctx.strokeRect(
        annotation.x * zoom + offset.x,
        annotation.y * zoom + offset.y,
        annotation.width * zoom,
        annotation.height * zoom
      );
      const annotation_startX =
        annotation.width > 0 ? annotation.x : annotation.x + annotation.width;
      const annotation_startY =
        annotation.height > 0 ? annotation.y : annotation.y + annotation.height;

      ctx.font = `bold ${24 * zoom}px Arial`;
      ctx.fillStyle = annotation.color;
      ctx.fillText(
        annotation.topic,
        annotation_startX * zoom + offset.x,
        annotation_startY * zoom + offset.y - 5
      );
    });

    // Draw the current rectangle being drawn
    if (isDrawing) {
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.strokeRect(
        startX * zoom + offset.x,
        startY * zoom + offset.y,
        (currentX - startX) * zoom,
        (currentY - startY) * zoom
      );
    }
  }, [
    image,
    annotations,
    zoom,
    offset,
    isDrawing,
    startX,
    startY,
    currentX,
    currentY,
  ]);

  const checkIfAnnotationExists = (pointX: number, pointY: number) => {
    for (const annotation of annotations) {
      const annotation_startX =
        annotation.width > 0 ? annotation.x : annotation.x + annotation.width;
      const annotation_startY =
        annotation.height > 0 ? annotation.y : annotation.y + annotation.height;
      const annotation_endX =
        annotation.width > 0 ? annotation.x + annotation.width : annotation.x;
      const annotation_endY =
        annotation.height > 0 ? annotation.y + annotation.height : annotation.y;
      if (
        pointX >= annotation_startX &&
        pointX <= annotation_endX &&
        pointY >= annotation_startY &&
        pointY <= annotation_endY
      ) {
        return annotation;
      }
    }
    return null;
  };

  const handleMouseWheel = (e: React.WheelEvent<HTMLCanvasElement>) => {
    // e.preventDefault();
    if (!e.shiftKey) return;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const zoomFactor = 0.2;
    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;
    if (e.deltaY < 0) {
      const newZoom = zoom + zoomFactor;

      const offsetX = mouseX - ((mouseX - offset.x) * newZoom) / zoom;
      const offsetY = mouseY - ((mouseY - offset.y) * newZoom) / zoom;

      setZoom(newZoom);
      setOffset({ x: offsetX, y: offsetY });
    } else {
      const newZoom = zoom - zoomFactor;
      if (newZoom < 1) {
        setOffset({ x: 0, y: 0 });
        return;
      }

      const offsetX = mouseX - ((mouseX - offset.x) * newZoom) / zoom;
      const offsetY = mouseY - ((mouseY - offset.y) * newZoom) / zoom;

      setZoom(newZoom);
      setOffset({ x: offsetX, y: offsetY });
    }
  };

  const handleMouseClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!editAccess) return;
    if (isDrawing) return;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    const pointX = (e.clientX - rect.left - offset.x) / zoom;
    const pointY = (e.clientY - rect.top - offset.y) / zoom;

    const annotation = checkIfAnnotationExists(pointX, pointY);
    if (annotation) {
      setCurrentAnnotation(annotation);
      handleOpen();
    }
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!editAccess) return;
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    const endX = (e.clientX - rect.left - offset.x) / zoom;
    const endY = (e.clientY - rect.top - offset.y) / zoom;
    const newAnnotation: Annotation = {
      id: Date.now().toString(),
      x: startX,
      y: startY,
      width: endX - startX,
      height: endY - startY,
      color,
      topic: "",
      description: "",
    };
    if (Math.abs(newAnnotation.width) > 0) {
      setCurrentAnnotation(newAnnotation);
      handleOpen();
    }
    setIsDrawing(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing) {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const rect = canvas.getBoundingClientRect();
      const endX = (e.clientX - rect.left - offset.x) / zoom;
      const endY = (e.clientY - rect.top - offset.y) / zoom;
      const annotation = checkIfAnnotationExists(endX, endY);
      setCurrentAnnotation(annotation);
      setMousePosition({ x: e.clientX, y: e.clientY });
      setAnchorEl(annotation ? e.currentTarget : null);
    } else {
      if (!editAccess) return;
      const canvas = canvasRef.current;
      if (!canvas) return;
      const rect = canvas.getBoundingClientRect();
      setCurrentX((e.clientX - rect.left - offset.x) / zoom);
      setCurrentY((e.clientY - rect.top - offset.y) / zoom);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!editAccess) return;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    setStartX((e.clientX - rect.left - offset.x) / zoom);
    setStartY((e.clientY - rect.top - offset.y) / zoom);
    setCurrentX((e.clientX - rect.left - offset.x) / zoom);
    setCurrentY((e.clientY - rect.top - offset.y) / zoom);
    setIsDrawing(true);
  };

  return (
    <div>
      <AnnotationDescription
        anchorEl={anchorEl}
        currentAnnotation={currentAnnotation}
        setAnchorEl={setAnchorEl}
        mouseX={mousePosition.x}
        mouseY={mousePosition.y}
      />
      <AnnotationDialogBox
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        currentAnnotation={currentAnnotation}
        setCurrentAnnotation={setCurrentAnnotation}
        annotations={annotations}
        setAnnotations={setAnnotations}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Button
            variant="contained"
            color="error"
            sx={{ marginLeft: 1 }}
            onClick={handleModelClose}
          >
            Cancel
          </Button>
        </Grid>
        {editAccess && (
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="start"
              alignItems="center"
            >
              <input
                type="color"
                style={{ height: "35px" }}
                value={color}
                onChange={(e) => {
                  setColor(e.target.value);
                }}
              />

              <Button
                onClick={handleSave}
                sx={{ ml: 1 }}
                color="success"
                variant="contained"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      <br />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>{loading && <CircularProgress color="secondary" />}</Grid>
        <Grid item>
          <canvas
            ref={canvasRef}
            onClick={handleMouseClick}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onWheel={handleMouseWheel}
            style={{ border: "1px solid black" }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ImageAnnotationTool;
