import { Box, Typography } from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import { APP_NAME } from "../../../constants";

const Heading = () => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  return (
    <Box
      sx={{
        backgroundColor: "rgba(10, 10, 10, 0.8)",
        padding: 4,
        mt: -1,
        display: { md: "flex" },
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <animated.div style={props}>
        <Typography
          variant="h3"
          color="#0099ff"
          sx={{
            fontFamily: "Helvetica",
          }}
        >
          Welcome to {APP_NAME}
        </Typography>
      </animated.div>
    </Box>
  );
};

export default Heading;
