import { Box, IconButton, Link, Stack, Typography } from "@mui/material";

import {
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  X as XIcon,
} from "@mui/icons-material";
import logo from "../../../assets/images/logo.webp";
import { APP_NAME } from "../../../constants";
import { useTheme } from "../../../ThemeProviderWrapper";

const logoStyle = {
  width: "50px",
  height: "auto",
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      <Link href="/">{APP_NAME}&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

const contactData = {
  email: "glazaiusa@gmail.com",
  tel: "8477780787",
  Adress: "1006, N Knollwood Dr Palatine IL 60067",
};

export default function Footer() {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <>
      {isDarkMode && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 4, sm: 8 },
            py: { xs: 8, sm: 10 },
            px: { xs: 3, sm: 10 },
            textAlign: { sm: "center", md: "left" },
            background: "linear-gradient(to bottom, #4f2a00, #000a12 )",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              width: "100%",
              justifyContent: "space-between",
              gap: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
                minWidth: { xs: "100%", sm: "60%" },
              }}
            >
              <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box sx={{ ml: "-15px" }}>
                    <img src={logo} style={logoStyle} alt="logo" />
                  </Box>
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    gutterBottom
                    sx={{
                      color: "#0099ff",
                    }}
                  >
                    Contact US
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignContent: "center",
                    gap: 1,
                  }}
                >
                  <Box sx={{ width: "60px" }}>Email:</Box>
                  <Box>{contactData.email}</Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignContent: "center",
                    gap: 1,
                  }}
                >
                  <Box sx={{ width: "60px" }}>Phone:</Box>
                  <Box>{contactData.tel}</Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignContent: "center",
                    gap: 1,
                  }}
                >
                  <Box sx={{ width: "60px" }}>Address:</Box>
                  <Box>{contactData.Adress}</Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="body2" fontWeight={600}>
                Product
              </Typography>
              <Link color="text.secondary" href="#">
                Services
              </Link>
              <Link color="text.secondary" href="#">
                Testimonials
              </Link>
              <Link color="text.secondary" href="#">
                Highlights
              </Link>
              <Link color="text.secondary" href="#">
                Pricing
              </Link>
              <Link color="text.secondary" href="#">
                FAQs
              </Link>
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="body2" fontWeight={600}>
                Legal
              </Typography>
              <Link color="text.secondary" href="#">
                Terms
              </Link>
              <Link color="text.secondary" href="#">
                Privacy
              </Link>
              <Link color="text.secondary" href="#">
                Contact
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: { xs: 4, sm: 8 },
              width: "100%",
              borderTop: "1px solid",
              borderColor: "divider",
            }}
          >
            <div>
              {/* <Link color="text.secondary" href="#">
            Privacy Policy
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="#">
            Terms of Service
          </Link> */}
              <Copyright />
            </div>
            <Stack
              direction="row"
              justifyContent="left"
              spacing={1}
              useFlexGap
              sx={{
                color: "text.secondary",
              }}
            >
              <IconButton
                color="inherit"
                href="https://github.com"
                aria-label="GitHub"
                sx={{ alignSelf: "center" }}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                color="inherit"
                href="https://twitter.com"
                aria-label="X"
                sx={{ alignSelf: "center" }}
              >
                <XIcon />
              </IconButton>
              <IconButton
                color="inherit"
                href="https://www.linkedin.com/"
                aria-label="LinkedIn"
                sx={{ alignSelf: "center" }}
              >
                <LinkedInIcon />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      )}

      {!isDarkMode && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 4, sm: 8 },
            py: { xs: 8, sm: 10 },
            px: { xs: 3, sm: 10 },
            textAlign: { sm: "center", md: "left" },
            background: "linear-gradient(to bottom, #e9e9e9, #0098fe)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              width: "100%",
              justifyContent: "space-between",
              gap: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
                minWidth: { xs: "100%", sm: "60%" },
              }}
            >
              <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box sx={{ ml: "-15px" }}>
                    <img src={logo} style={logoStyle} alt="logo" />
                  </Box>
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    gutterBottom
                    sx={{
                      color: "#0099ff",
                    }}
                  >
                    Contact US
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignContent: "center",
                    gap: 1,
                  }}
                >
                  <Box sx={{ width: "60px" }}>Email:</Box>
                  <Box>{contactData.email}</Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignContent: "center",
                    gap: 1,
                  }}
                >
                  <Box sx={{ width: "60px" }}>Phone:</Box>
                  <Box>{contactData.tel}</Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignContent: "center",
                    gap: 1,
                  }}
                >
                  <Box sx={{ width: "60px" }}>Address:</Box>
                  <Box>{contactData.Adress}</Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="body2" fontWeight={600}>
                Product
              </Typography>
              <Link color="text.secondary" href="#">
                Services
              </Link>
              <Link color="text.secondary" href="#">
                Testimonials
              </Link>
              <Link color="text.secondary" href="#">
                Highlights
              </Link>
              <Link color="text.secondary" href="#">
                Pricing
              </Link>
              <Link color="text.secondary" href="#">
                FAQs
              </Link>
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="body2" fontWeight={600}>
                Legal
              </Typography>
              <Link color="text.secondary" href="#">
                Terms
              </Link>
              <Link color="text.secondary" href="#">
                Privacy
              </Link>
              <Link color="text.secondary" href="#">
                Contact
              </Link>
            </Box>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: { xs: 4, sm: 8 },
              width: "100%",
              borderTop: "1px solid",
              borderColor: "divider",
            }}
          >
            <div>
              <Copyright />
            </div>
            <Stack
              direction="row"
              justifyContent="left"
              spacing={1}
              useFlexGap
              sx={{
                color: "text.secondary",
              }}
            >
              <IconButton
                color="inherit"
                href="https://www.facebook.com"
                aria-label="FaceBook"
                sx={{ alignSelf: "center" }}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                color="inherit"
                href="https://twitter.com"
                aria-label="X"
                sx={{ alignSelf: "center" }}
              >
                <XIcon />
              </IconButton>
              <IconButton
                color="inherit"
                href="https://www.linkedin.com"
                aria-label="LinkedIn"
                sx={{ alignSelf: "center" }}
              >
                <LinkedInIcon />
              </IconButton>
            </Stack>
          </Box> */}
        </Box>
      )}
    </>
  );
}
