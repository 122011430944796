import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ReviewRequest } from "../../../utils/interfaces";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import { BACKEND_APP_URL } from "../../../constants";
import { toast } from "react-toastify";
import { useAuth } from "../../../hooks/useAuth";

interface ChildProps {
  data: ReviewRequest | null;
  setTaskUpdated: (arg0: boolean) => void;
  selectedOperator: string;
  setSelectedOperator: (arg0: string) => void;
  confimationModelOpen: boolean;
  setConfimationModelOpen: (arg0: boolean) => void;
}

const AssignDroneOperatorConfirmation: React.FC<ChildProps> = ({
  data,
  setTaskUpdated,
  selectedOperator,
  confimationModelOpen,
  setConfimationModelOpen,
  setSelectedOperator,
}) => {
  const handleClose = () => {
    setConfimationModelOpen(false);
    setSelectedOperator("");
  };

  const auth = useAuth();
  const user = auth?.user;

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      performRequestWithRetry(
        `${BACKEND_APP_URL}/api/business-manager/requests/${data?._id}`,
        "PUT",
        {
          status: "Assigned", // use REQUEST_STATUS enum
          droneOperator: selectedOperator,
        },
        user,
        auth.logout
      )
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("Drone Operator assigned successfully");
            setTaskUpdated(true);
          }
        })
        .catch((error) => {
          console.error("Error assigning drone operator", error);
          toast.error("Error assigning drone operator");
        });
    } else {
      setSelectedOperator("");
    }

    setConfimationModelOpen(false);
  };

  return (
    <>
      <Dialog
        open={confimationModelOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to add this person as Drone Operator?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              handleConfirm(false);
            }}
          >
            No
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              handleConfirm(true);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssignDroneOperatorConfirmation;
