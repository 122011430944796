import { useState } from "react";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { toast } from "react-toastify";

const FolderCreationSection = ({
  folders,
  setFolders,
}: {
  folders: any;
  setFolders: (value: any[]) => void;
}) => {
  const [folderName, setFolderName] = useState("");

  const handleCreateFolder = () => {
    //folder name already exists in folders
    if (folders.find((folder: any) => folder.name === folderName)) {
      toast.error("Folder already exists");
      return;
    }
    //strip folder name and check if it is empty
    if (folderName === "") {
      toast.error("Folder name is required");
      return;
    }

    setFolders([...folders, { id: folders.length + 1, name: folderName }]);
    toast.success("Folder created successfully");
    setFolderName("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "30%",
      }}
    >
      <TextField
        focused
        size="small"
        placeholder="New folder name"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value.trim())}
        fullWidth={true}
        helperText="Please Use Underscores Instead of Spaces"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleCreateFolder} edge="end">
                <CreateNewFolderIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default FolderCreationSection;
