import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { LANDING } from "../constants";

export const ProtectedRoute = ({ children }: any) => {
  const userContext = useAuth();
  if (userContext.user === null) {
    return <Navigate to={`/${LANDING}`} />;
  }
  return children;
};
