import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ReviewRequestCard } from "./ReviewRequestCard";
import { ReviewRequest } from "../../../utils/interfaces";
import ReveiwRequestForm from "./RequestDetailsForm";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import { BACKEND_APP_URL, RERVIEW_REQUEST_STATUS } from "../../../constants";
import { useAuth } from "../../../hooks/useAuth";

export default function BMReviewRequestsComponent() {
  const reviewRequestsPerPage = 6;
  const [reviewRequests, setReviewRequests] = useState<ReviewRequest[]>();
  const [filteredReviewRequests, setFilteredReviewRequests] = useState<
    ReviewRequest[]
  >([]);
  const [selectedReviewRequests, setSelectedReviewRequests] = useState<
    ReviewRequest[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [currentChurch, setCurrentChurch] = useState<ReviewRequest | null>(
    null
  );
  const auth = useAuth();
  const user = auth?.user;
  const [reviewRequestUpdated, setReviewRequestUpdated] = useState(false);
  const [statusFilter, setStatusFilter] = useState(
    RERVIEW_REQUEST_STATUS.REQUESTED
  );

  useEffect(() => {
    setLoading(true);
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/business-manager/requests`,
      "GET",
      null,
      user,
      auth.logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          setReviewRequests(
            response.data.sort((a: ReviewRequest, b: ReviewRequest) =>
              new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
            )
          );
          setFilteredReviewRequests(
            response.data
              .filter((req: any) => req.status === statusFilter)
              .sort((a: ReviewRequest, b: ReviewRequest) =>
                new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
              )
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching review requests", error);
        setLoading(false);
      });
  }, [reviewRequestUpdated]);

  useEffect(() => {
    const startIndex = 0;
    const endIndex = reviewRequestsPerPage;

    setSelectedReviewRequests(
      reviewRequests
        ?.filter((req: any) => req.status === statusFilter)
        ?.slice(startIndex, endIndex) || []
    );
  }, [filteredReviewRequests]);

  useEffect(() => {
    setCurrentChurch(currentChurch);
  }, [open]);

  const handleStatusFilter = (status: string) => {
    switch (status) {
      case RERVIEW_REQUEST_STATUS.REQUESTED:
        setStatusFilter(RERVIEW_REQUEST_STATUS.REQUESTED);
        break;
      case RERVIEW_REQUEST_STATUS.PAYEMENT_PENDING:
        setStatusFilter(RERVIEW_REQUEST_STATUS.PAYEMENT_PENDING);
        break;
      case RERVIEW_REQUEST_STATUS.REJECTED:
        setStatusFilter(RERVIEW_REQUEST_STATUS.REJECTED);
        break;
      case RERVIEW_REQUEST_STATUS.PAID:
        setStatusFilter(RERVIEW_REQUEST_STATUS.PAID);
        break;
      case RERVIEW_REQUEST_STATUS.ASSIGNED:
        setStatusFilter(RERVIEW_REQUEST_STATUS.ASSIGNED);
        break;
      case RERVIEW_REQUEST_STATUS.EXPIRED:
        setStatusFilter(RERVIEW_REQUEST_STATUS.EXPIRED);
        break;
      default:
        setStatusFilter(RERVIEW_REQUEST_STATUS.REQUESTED);
    }

    // reviewRequests?.forEach((req: ReviewRequest) => {
    //   console.log("req", req.manager, req.status);
    // });

    setFilteredReviewRequests(
      reviewRequests?.filter((req: ReviewRequest) => req.status === status) ||
        []
    );
  };

  const handlePageChange = (event: any, value: number) => {
    const startIndex = (value - 1) * reviewRequestsPerPage;
    const endIndex = value * reviewRequestsPerPage;

    setSelectedReviewRequests(
      filteredReviewRequests?.slice(startIndex, endIndex) || []
    );
  };

  return (
    <div>
      <ReveiwRequestForm
        open={open}
        setOpen={setOpen}
        data={currentChurch}
        setReviewRequestUpdated={setReviewRequestUpdated}
      />
      <Typography variant="h5">Manage Review Requests</Typography>
      <ButtonGroup
        sx={{ m: 1 }}
        variant="outlined"
        aria-label="Basic button group"
        disabled={loading}
      >
        <Button
          onClick={() => handleStatusFilter(RERVIEW_REQUEST_STATUS.REQUESTED)}
          variant={
            statusFilter == RERVIEW_REQUEST_STATUS.REQUESTED
              ? "contained"
              : "outlined"
          }
        >
          Requested
        </Button>
        <Button
          onClick={() =>
            handleStatusFilter(RERVIEW_REQUEST_STATUS.PAYEMENT_PENDING)
          }
          variant={
            statusFilter == RERVIEW_REQUEST_STATUS.PAYEMENT_PENDING
              ? "contained"
              : "outlined"
          }
        >
          Payment Pending
        </Button>
        <Button
          onClick={() => handleStatusFilter(RERVIEW_REQUEST_STATUS.REJECTED)}
          variant={
            statusFilter == RERVIEW_REQUEST_STATUS.REJECTED
              ? "contained"
              : "outlined"
          }
        >
          Rejected
        </Button>
        <Button
          onClick={() => handleStatusFilter(RERVIEW_REQUEST_STATUS.PAID)}
          variant={
            statusFilter == RERVIEW_REQUEST_STATUS.PAID
              ? "contained"
              : "outlined"
          }
        >
          Paid
        </Button>
        <Button
          onClick={() => handleStatusFilter(RERVIEW_REQUEST_STATUS.ASSIGNED)}
          variant={
            statusFilter == RERVIEW_REQUEST_STATUS.ASSIGNED
              ? "contained"
              : "outlined"
          }
        >
          Assigned
        </Button>
        <Button
          onClick={() => handleStatusFilter(RERVIEW_REQUEST_STATUS.EXPIRED)}
          variant={
            statusFilter == RERVIEW_REQUEST_STATUS.EXPIRED
              ? "contained"
              : "outlined"
          }
        >
          Expired
        </Button>
      </ButtonGroup>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="start"
      >
        <Grid item>
          <Box
            sx={{
              m: 2,
              display: { xs: "none", lg: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {loading && <CircularProgress color="secondary" />}
              {!loading &&
                selectedReviewRequests != null &&
                selectedReviewRequests.length === 0 && (
                  <Typography>No Review Requests</Typography>
                )}
              {!loading &&
                selectedReviewRequests != null &&
                selectedReviewRequests.map((req: any, index) => {
                  if (index % 2 == 0) {
                    return (
                      <ReviewRequestCard
                        key={index}
                        church={req}
                        setOpen={setOpen}
                        setCurrentChurch={setCurrentChurch}
                        setReviewRequestUpdated={setReviewRequestUpdated}
                      />
                    );
                  }
                })}
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              m: 2,
              display: { xs: "none", lg: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {!loading &&
                selectedReviewRequests != null &&
                selectedReviewRequests.map((req: any, index) => {
                  if (index % 2 == 1) {
                    return (
                      <ReviewRequestCard
                        key={index}
                        church={req}
                        setOpen={setOpen}
                        setCurrentChurch={setCurrentChurch}
                        setReviewRequestUpdated={setReviewRequestUpdated}
                      />
                    );
                  }
                })}
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              m: 2,
              display: { xs: "flex", lg: "none" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {!loading &&
                selectedReviewRequests != null &&
                selectedReviewRequests.map((req: any, index) => {
                  return (
                    <ReviewRequestCard
                      key={index}
                      church={req}
                      setOpen={setOpen}
                      setCurrentChurch={setCurrentChurch}
                      setReviewRequestUpdated={setReviewRequestUpdated}
                    />
                  );
                })}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignContent={"center"}
        flexDirection={"row"}
      >
        {!loading && selectedReviewRequests.length !== 0 && (
          <Pagination
            count={Math.ceil(
              filteredReviewRequests.length / reviewRequestsPerPage
            )}
            color="secondary"
            onChange={handlePageChange}
          />
        )}
      </Box>
    </div>
  );
}
