import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Button } from "@mui/material";
import { MenuItem, TextField, Typography } from "@mui/material";
import { ROLE_MAINTENANCEENGINEER } from "../../constants";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

interface Inputs {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
}

const schema = z.object({
  first_name: z.string().min(3, "First name should have at least 3 characters"),
  last_name: z.string().min(3, "Last name should have at least 3 characters"),
  email: z.string().email(),
  role: z.enum([ROLE_MAINTENANCEENGINEER]),
});

interface EditMaintenanceEngineerFormDialogProps {
  open: boolean;
  onClose: (
    maintenanceEngineer: {
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      role: string;
    } | null
  ) => void;
  maintenanceEngineer:
    | {
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        created_at: string;
        role: string;
      }
    | undefined;
}

const roles = [
  {
    value: ROLE_MAINTENANCEENGINEER,
    label: "Maintenance Engineer",
  },
];

export default function EditMaintenanceEngineerFormDialog(
  props: EditMaintenanceEngineerFormDialogProps
) {
  const { open, onClose } = props;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      first_name: props.maintenanceEngineer?.first_name,
      last_name: props.maintenanceEngineer?.last_name,
      email: props.maintenanceEngineer?.email,
      role: props.maintenanceEngineer?.role,
    },
  });

  console.log(errors);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const first_name = data.first_name;
    const last_name = data.last_name;
    const email = data.email;
    const role = data.role;

    const maintenanceEngineer = {
      id: props.maintenanceEngineer?.id as string,
      first_name: first_name as string,
      last_name: last_name as string,
      email: email as string,
      role: role as string,
    };
    onClose(maintenanceEngineer);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        onClose(null);
      }}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle variant="h4">Edit Maintenance Engineer</DialogTitle>
      <DialogContent>
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          First Name
        </Typography>
        <Controller
          name="first_name"
          control={control}
          defaultValue={props.maintenanceEngineer?.first_name}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="first_name"
              type="text"
              fullWidth
              variant="outlined"
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
            />
          )}
        />

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Last Name
        </Typography>
        <Controller
          name="last_name"
          control={control}
          defaultValue={props.maintenanceEngineer?.last_name}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="last_name"
              type="text"
              fullWidth
              variant="outlined"
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
            />
          )}
        />

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Email
        </Typography>

        <Controller
          name="email"
          control={control}
          defaultValue={props.maintenanceEngineer?.email}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="email"
              type="email"
              fullWidth
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Role
        </Typography>
        <Controller
          name="role"
          control={control}
          defaultValue={props.maintenanceEngineer?.role}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="role"
              select
              name="role"
              // label="Role"
              fullWidth
              variant="outlined"
            >
              {roles.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </DialogContent>
      <DialogActions style={{ padding: "20px" }}>
        <Button
          onClick={() => {
            onClose(null);
          }}
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>
        <Button type="submit" variant="outlined" size="large">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
