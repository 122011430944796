import {
  Box,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import church_request from "../../../assets/images/church_req.webp";
import React, { useState } from "react";
import { DroneOperator, ReviewRequest } from "../../../utils/interfaces";
import AssignDroneOperatorConfirmation from "./Confirmation";

interface ChildProps {
  church: ReviewRequest;
  setTaskUpdated: (arg0: boolean) => void;
  droneOperators: DroneOperator[];
}

export const DroneOperatorAssignCard: React.FC<ChildProps> = ({
  church,
  droneOperators,
  setTaskUpdated,
}) => {
  const [confimationModelOpen, setConfimationModelOpen] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState("");

  function handleChange(event: SelectChangeEvent<string>): void {
    setSelectedOperator(event.target.value);
    setConfimationModelOpen(true);
  }

  return (
    <>
      <Card sx={{ width: { xs: "80vw", sm: 500 }, height: 185 }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: 1, sm: 3 },
          }}
        >
          <Box
            component="img"
            src={church.images[0] ? church.images[0] : church_request}
            alt="random"
            sx={{
              width: 150,
              height: 150,
              objectFit: "cover",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Typography
              variant="h6"
              color="text.primary"
              sx={{ fontWeight: 600 }}
            >
              {church.name}
            </Typography>
            <Typography
              variant="caption"
              color="text.primary"
              sx={{ fontWeight: 500 }}
            >
              Requested by: {church.manager}
            </Typography>
            <Typography
              variant="caption"
              color="text.primary"
              sx={{ fontWeight: 500 }}
            >
              Requested date: {church.created_at.split("T")[0]}
            </Typography>
            <Typography
              variant="caption"
              color="text.primary"
              sx={{ fontWeight: 500 }}
            >
              Contact: {church.email}
            </Typography>
            {church.status === "Assigned" && (
              <Typography
                variant="caption"
                color="text.primary"
                sx={{ fontWeight: 500 }}
              >
                Assigned Drone Operator: {church.droneOperator}
              </Typography>
            )}
            {church.status === "Paid" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  mr: 3,
                }}
              >
                <FormControl sx={{ mt: 1, minWidth: 250 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Select the Drone Operator
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedOperator}
                    label="Drone Operator"
                    onChange={handleChange}
                  >
                    {droneOperators.map((droneOperator, index) => (
                      <MenuItem key={index} value={droneOperator._id}>
                        {`${droneOperator.first_name} ${droneOperator.last_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <AssignDroneOperatorConfirmation
        data={church}
        setTaskUpdated={setTaskUpdated}
        selectedOperator={selectedOperator}
        confimationModelOpen={confimationModelOpen}
        setConfimationModelOpen={setConfimationModelOpen}
        setSelectedOperator={setSelectedOperator}
      />
    </>
  );
};

export default DroneOperatorAssignCard;
