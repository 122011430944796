import React, { useEffect, useState } from "react";

import { useAuth } from "../../../hooks/useAuth";
import { BACKEND_APP_URL } from "../../../constants";
import { ToastContainer, toast } from "react-toastify";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import FolderCreationSection from "./FolderCreationSection";
import CreatedFolders from "./CreatedFolders";
import Uploader from "./Uploader";

const UploadImagesComponent: React.FC = () => {
  const [images, setImages] = useState<{ data: string; name: string }[]>([]);
  const auth = useAuth();
  const user = auth?.user;
  const logout = auth.logout;
  const [folderName, setFolderName] = useState("");
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [folders, setFolders] = useState([] as any);

  useEffect(() => {
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/maintenance-engineer/gallery/${user.created_by}`,
      "GET",
      null,
      user,
      logout
    )
      .then((response) => {
        const folderList = response?.data.map((folder: any) => {
          return {
            id: folder._id,
            name: folder.folder_name,
          };
        });
        console.log(folderList);

        setFolders(folderList);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to fetch folders");
      });
  }, [logout, user]);

  return (
    <>
      <ToastContainer />
      <FolderCreationSection folders={folders} setFolders={setFolders} />
      <CreatedFolders
        folders={folders}
        setFolderName={setFolderName}
        setFold={setUploadModalIsOpen}
      />
      <Uploader
        images={images}
        folderName={folderName}
        setImages={setImages}
        setFold={setUploadModalIsOpen}
        uploadModalIsOpen={uploadModalIsOpen}
        handleUploadModalClose={() => setUploadModalIsOpen(false)}
      />
    </>
  );
};

export default UploadImagesComponent;
