import { ChangeEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import { BACKEND_APP_URL } from "../../../constants";
import { useTheme } from "../../../ThemeProviderWrapper";
import workshop_engineer from "../../../assets/images/workshop - ads page backround side image.png";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { getCitiesOfState, getStates } from "../../../utils/commonFunctions";
import { City } from "../../../utils/interfaces";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import NavBar from "../LandingComponent/NavBar";
import Footer from "../Footer/Footer";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)({
  // display: { xs: "none", sm: "block" },
  width: "40%",
  height: "auto",
  perspective: "1000px", // Perspective for 3D effect
  "& img": {
    width: "100%",
    height: "auto",
    transform: "rotateY(-20deg) rotateX(10deg)", // 3D rotation
    transition: "transform 0.5s", // Smooth transition
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)", // Shadow for depth
  },
  "&:hover img": {
    transform: "rotateY(0deg) rotateX(0deg) scale(1.05)", // Scale and reset rotation on hover
  },
});

interface Inputs {
  name: string;
  city: string;
  state: string;
  email: string;
  contact_no: string;
  image: string;
}

const phoneNumberRegex = /^\+1[- ]?(\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
const schema = z.object({
  name: z.string().min(3, { message: "Name should be atleast 3 characters" }),
  city: z.string().min(1, { message: "City is required" }),
  state: z.string().min(1, { message: "State is required" }),
  email: z.string().email(),
  contact_no: z.string().refine((value) => phoneNumberRegex.test(value), {
    message: "Phone number format should be +x (xxx) xxx-xxxx",
  }),
  image: z.string().min(1, { message: "Image is required" }),
});

export default function WorkshopEngineerComponent() {
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors, isDirty },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      city: "",
      state: "",
      email: "",
      contact_no: "",
      image: "",
    },
    mode: "onChange",
  });

  const { isDarkMode, toggleTheme } = useTheme();
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    state: "",
    email: "",
    contact_no: "",
    image: "",
  });
  const [image, setImage] = useState<string | null>(null);
  const [USAcities, setUSAcities] = useState<City[]>([]);
  const [state, setState] = useState<string | null>(null);
  const [USAstates, setUSAstates] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    (async () => {
      if (isDirty) {
        await trigger("image");
      }
    })();
  }, [image, isDirty, trigger]);

  // const USAstates = getStates();

  useEffect(() => {
    try {
      const states = getStates();
      setUSAstates(states);
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    if (state) {
      const cities = getCitiesOfState(state);
      setUSAcities(cities);
    }
  }, [state]);

  useEffect(() => {
    if (isDarkMode) {
      toggleTheme();
    }
  }, [isDarkMode, toggleTheme]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(data);
    const reqBody = data;
    axios
      .post(`${BACKEND_APP_URL}/api/workshop`, reqBody)
      .then((response) => {
        toast.success("Advertisement submitted successfully");
        setImage(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Box>
      <ToastContainer />
      <NavBar />
      <CssBaseline />

      <Box
        sx={{
          m: 3,
        }}
      >
        <Typography variant="h4" sx={{ mt: 2 }}>
          Fill the following form to post your advertisement
        </Typography>
        <Box
          sx={{
            mt: 2,
            mr: 5,
            ml: 5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  maxWidth: "500px",
                  margin: "auto",
                }}
              >
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      label="Workshop Name"
                      placeholder="Workshop Name"
                      // required
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      {...field}
                    />
                  )}
                />

                <Autocomplete
                  options={USAstates}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => {
                    console.log(params);

                    return (
                      <TextField
                        {...params}
                        label="State"
                        placeholder="State"
                        error={!!errors.state}
                        helperText={errors.state?.message}
                      />
                    );
                  }}
                  onChange={(e, value) => {
                    console.log(value);

                    if (value) {
                      console.log(e);
                      setValue("state", value.value);
                      setState(value.value);
                      trigger("state");
                    }
                  }}
                />

                <Autocomplete
                  options={USAcities}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      placeholder="City"
                      error={!!errors.city}
                      helperText={errors.city?.message}
                    />
                  )}
                  onChange={(e, value) => {
                    if (value) {
                      setValue("city", value.value);
                      trigger("city");
                    }
                  }}
                />

                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="email"
                      label="Email"
                      placeholder="Email"
                      // required
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="contact_no"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      label="Contact Number"
                      placeholder="Contact Number"
                      // required
                      error={!!errors.contact_no}
                      helperText={errors.contact_no?.message}
                      {...field}
                    />
                  )}
                />

                <TextField
                  type="file"
                  placeholder="Image"
                  value={formData.image}
                  error={!!errors.image}
                  helperText={errors.image?.message}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, image: e.target.value });
                    const file = e.target.files ? e.target.files[0] : null;
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = () => {
                        if (typeof reader.result === "string") {
                          setImage(reader.result);
                          setValue("image", reader.result);
                        }
                      };
                      reader.readAsDataURL(file);
                    } else {
                      setImage("");
                      setValue("image", "");
                    }
                  }}
                />
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
          <StyledBox>
            <Box
              component="img"
              sx={{
                display: { xs: "none", sm: "block" },
                width: "40%",
                height: "auto",
              }}
              src={workshop_engineer}
              alt="random2"
            />
          </StyledBox>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
