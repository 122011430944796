import { useState, useEffect } from "react";
import { ReviewRequest, DroneOperator } from "../../../utils/interfaces";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Button,
  ButtonGroup,
  Pagination,
} from "@mui/material";
import DroneOperatorAssignCard from "./DroneOperatorAssignCard";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import { BACKEND_APP_URL, RERVIEW_REQUEST_STATUS } from "../../../constants";
import { useAuth } from "../../../hooks/useAuth";

const DroneOperatorAssignComponent = () => {
  const churchePerPage = 6;
  const [churches, setChurches] = useState<ReviewRequest[]>([]);
  const [filteredChurches, setFilteredChurches] = useState<ReviewRequest[]>([]);
  const [selectedChurches, setSelectedChurches] = useState<ReviewRequest[]>([]);
  const [filter, setFilter] = useState(RERVIEW_REQUEST_STATUS.PAID);
  const [droneOperators, setDroneOperators] = useState<DroneOperator[]>([]);
  const [loading, setLoading] = useState(false);
  const [droneOperatorLoading, setDroneOperatorLoading] = useState(false);
  const [taskUpdated, setTaskUpdated] = useState(false);

  const auth = useAuth();
  const user = auth?.user;

  useEffect(() => {
    // fetch accepted requests
    // fetch assigned drone operator details if assigned to a drone operator @Akila-I
    setLoading(true);
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/business-manager/requests/to-assign`,
      "GET",
      null,
      user,
      auth.logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          setChurches(
            response.data.sort((a: ReviewRequest, b: ReviewRequest) =>
              new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
            )
          );
          setFilteredChurches(
            response.data
              .filter((req: ReviewRequest) => req.status === filter)
              .sort((a: ReviewRequest, b: ReviewRequest) =>
                new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
              )
          );
          const startIndex = 0;
          const endIndex = churchePerPage;
          setSelectedChurches(
            response.data
              .filter((req: ReviewRequest) => req.status === filter)
              .slice(startIndex, endIndex)
              .sort((a: ReviewRequest, b: ReviewRequest) =>
                new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
              )
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching review requests", error);
        setLoading(false);
      });

    // fetch drone operator details
    setDroneOperatorLoading(true);
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/drone-operators`,
      "GET",
      null,
      user,
      auth.logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          setDroneOperators(response.data);
        }
        setDroneOperatorLoading(false);
      })
      .catch((error) => {
        setDroneOperatorLoading(false);
      });
  }, [taskUpdated]);

  useEffect(() => {
    const startIndex = 0;
    const endIndex = churchePerPage;
    setSelectedChurches(filteredChurches.slice(startIndex, endIndex));
  }, [filteredChurches]);

  const handleFilter = (status: string) => {
    switch (status) {
      case RERVIEW_REQUEST_STATUS.PAID:
        setFilter(RERVIEW_REQUEST_STATUS.PAID);
        setFilteredChurches(
          churches.filter((req) => req.status === RERVIEW_REQUEST_STATUS.PAID)
        );
        break;
      case RERVIEW_REQUEST_STATUS.ASSIGNED:
        setFilter(RERVIEW_REQUEST_STATUS.ASSIGNED);
        setFilteredChurches(
          churches.filter(
            (req) => req.status === RERVIEW_REQUEST_STATUS.ASSIGNED
          )
        );
        break;
      default:
        setFilter(RERVIEW_REQUEST_STATUS.PAID);
        setFilteredChurches(
          churches.filter((req) => req.status === RERVIEW_REQUEST_STATUS.PAID)
        );
    }
    setFilteredChurches(churches.filter((req) => req.status === status));
  };

  const handlePageChange = (event: any, value: number) => {
    const startIndex = (value - 1) * churchePerPage;
    const endIndex = value * churchePerPage;
    setSelectedChurches(filteredChurches.slice(startIndex, endIndex));
  };

  return (
    <div>
      <Typography variant="h5">Assign Drone Operator</Typography>
      <ButtonGroup
        disabled={loading || droneOperatorLoading}
        sx={{ mt: 2 }}
        variant="outlined"
        aria-label="Basic button group"
      >
        <Button
          onClick={() => {
            handleFilter(RERVIEW_REQUEST_STATUS.PAID);
          }}
          variant={
            filter == RERVIEW_REQUEST_STATUS.PAID ? "contained" : "outlined"
          }
        >
          Unassigned
        </Button>
        <Button
          onClick={() => {
            handleFilter(RERVIEW_REQUEST_STATUS.ASSIGNED);
          }}
          variant={
            filter == RERVIEW_REQUEST_STATUS.ASSIGNED ? "contained" : "outlined"
          }
        >
          Assigned
        </Button>
      </ButtonGroup>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="start"
      >
        <Grid item>
          {(loading || droneOperatorLoading) && (
            <CircularProgress color="secondary" />
          )}
        </Grid>
        <Grid item>
          {!loading &&
            !droneOperatorLoading &&
            filteredChurches.length === 0 && (
              <Typography>No Tasks to assign</Typography>
            )}
        </Grid>

        <Grid item>
          <Box
            sx={{
              m: 2,
              display: { xs: "none", lg: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {!loading &&
                !droneOperatorLoading &&
                selectedChurches.map((req: any, index) => {
                  if (index % 2 == 0) {
                    return (
                      <DroneOperatorAssignCard
                        key={index}
                        church={req}
                        setTaskUpdated={setTaskUpdated}
                        droneOperators={droneOperators}
                      />
                    );
                  }
                })}
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              m: 2,
              display: { xs: "none", lg: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {!loading &&
                !droneOperatorLoading &&
                selectedChurches.map((req: any, index) => {
                  if (index % 2 == 1) {
                    return (
                      <DroneOperatorAssignCard
                        key={index}
                        church={req}
                        setTaskUpdated={setTaskUpdated}
                        droneOperators={droneOperators}
                      />
                    );
                  }
                })}
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              m: 2,
              display: { xs: "flex", lg: "none" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {!loading &&
                !droneOperatorLoading &&
                selectedChurches.map((req: any, index) => {
                  return (
                    <DroneOperatorAssignCard
                      key={index}
                      church={req}
                      setTaskUpdated={setTaskUpdated}
                      droneOperators={droneOperators}
                    />
                  );
                })}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        {!loading && !droneOperatorLoading && filteredChurches.length !== 0 && (
          <Pagination
            count={Math.ceil(filteredChurches.length / churchePerPage)}
            color="secondary"
            onChange={handlePageChange}
          />
        )}
      </Box>
    </div>
  );
};

export default DroneOperatorAssignComponent;
