import { City } from "country-state-city";
import { ICity } from "country-state-city";
import { usaStates } from "./usaStates";
import { usaStateCities } from "./usaStateAndCities";

const countryCode = "US";

//read us_cities_states_counties.csv file
// export const readCSV = async () => {
//   const response = await fetch("us_cities_states_counties.csv");
//   const data = await response.text();
//   return data;
// };

// interface IStatesAndCities {
//   state: string;
//   stateCode: string;
//   city: string;
// }

// //check availability of state in the list
// function objectExistsInArray(
//   array: IStatesAndCities[],
//   obj: IStatesAndCities
// ): boolean {
//   return array.some(
//     (item) =>
//       item.stateCode === obj.stateCode &&
//       item.state === obj.state &&
//       item.city === obj.city
//   );
// }

// //create list of distinct objects from csv data.
// export const createList = (data: string) => {
//   const rows = data.split("\n").slice(1);
//   const list = rows.map((row) => {
//     const columns = row.split("|");
//     return {
//       city: columns[0],
//       stateCode: columns[1],
//       state: columns[2],
//     };
//   });

//   const distinctList: IStatesAndCities[] = [];
//   list.forEach((item) => {
//     if (!objectExistsInArray(distinctList, item)) {
//       distinctList.push(item);
//     }
//   });

//   return distinctList;
// };

// // get cities of a state
// export const getCitiesOfState = (
//   stateCode: string,
//   list: IStatesAndCities[]
// ) => {
//   const cities = list
//     .filter((item) => item.stateCode === stateCode)
//     .map((item) => {
//       return {
//         value: item.city,
//         label: item.city,
//       };
//     });

//   return cities;
// };

// // get distinct states
// export const getDistinctStates = (list: IStatesAndCities[]) => {
//   const states = list.map((item) => {
//     return {
//       value: item.stateCode,
//       label: item.state,
//     };
//   });

//   const distinctList: { value: string; label: string }[] = [];
//   states.forEach((item) => {
//     if (!distinctList.some((state) => state.value === item.value)) {
//       distinctList.push(item);
//     }
//   });

//   return distinctList;
// };

// read state_full.json file and create ts object
export const readStates = async () => {
  const response = await fetch("state_full.json");
  const data = await response.json();
  return data;
};

// read state_city.json file and create ts object
export const readCities = async () => {
  const response = await fetch("state_cities.json");
  const data = await response.json();
  return data;
};

// get cities of a state
export const getCitiesOfState = (stateCode: string) => {
  const data: { [key: string]: string[] } = usaStateCities;
  const cities = data[stateCode].map((city: string) => {
    return {
      value: city,
      label: city,
    };
  });

  return cities;
};

export const getStates = (): {
  value: string;
  label: string;
}[] => {
  const states: { [key: string]: string } = usaStates;
  const stateList = Object.keys(states).map((stateCode) => {
    return {
      value: stateCode,
      label: states[stateCode],
    };
  });
  return stateList;
};

// const states = State.getStatesOfCountry(countryCode).map((state) => {
//   // if (USAStates.includes(state.name)) {
//   return {
//     value: state.isoCode,
//     label: state.name,
//   };
//   // }
// });

// return states;

export const getCities = (stateCode: string) => {
  const cities = City.getCitiesOfState(countryCode, stateCode).map(
    (city: ICity) => {
      return {
        value: city.name,
        label: city.name,
      };
    }
  );

  return cities;
};
