import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { AddPhotoAlternate } from "@mui/icons-material";
import { useAuth } from "../../../hooks/useAuth";
import { BACKEND_APP_URL } from "../../../constants";
import { toast } from "react-toastify";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import { useState } from "react";

const Uploader = ({
  images,
  folderName,
  setImages,
  setFold,
  uploadModalIsOpen,
  handleUploadModalClose,
}: {
  images: { data: string; name: string }[];
  folderName: string;
  setImages: React.Dispatch<
    React.SetStateAction<{ data: string; name: string }[]>
  >;
  setFold: React.Dispatch<React.SetStateAction<boolean>>;
  uploadModalIsOpen: boolean;
  handleUploadModalClose: () => void;
}) => {
  const auth = useAuth();
  const user = auth?.user;
  const logout = auth.logout;
  const [uploading, setUploading] = useState(false);

  console.log("images", images);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newImages: { data: string; name: string }[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = () => {
          if (typeof reader.result === "string") {
            newImages.push({ data: reader.result, name: file.name });
            if (newImages.length === files.length) {
              setImages((prevImages: { data: string; name: string }[]) => [
                ...prevImages,
                ...newImages,
              ]);
            }
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSaveImages = () => {
    // Logic to save images to gallery
    const reqBody = {
      folder_name: folderName,
      images: images,
      manager_id: user.created_by,
    };
    console.log(reqBody);

    setUploading(true);
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/maintenance-engineer/gallery`,
      "POST",
      reqBody,
      user,
      logout
    )
      .then((data) => {
        toast.success("Images saved successfully", {
          theme: "dark",
        });
        setImages([]);
        setFold(false);
        setUploading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to save images", {
          theme: "colored",
        });
        setUploading(false);
      });
  };

  console.log("uploading", uploading);

  return (
    <Dialog
      open={uploadModalIsOpen}
      onClose={handleUploadModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Grid container spacing={3}>
            <Typography
              variant="h4"
              color={"text.primary"}
              sx={{ fontWeight: 600, ml: 2 }}
            >
              Upload Images to {folderName}
            </Typography>
            <Grid item xs={12}>
              <Paper
                sx={{ p: 2, textAlign: "center", color: "text.secondary" }}
              >
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="contained-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <AddPhotoAlternate />
                  </IconButton>
                  <Typography variant="body1">Upload Images</Typography>
                </label>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveImages}
                fullWidth
                disabled={images.length === 0 || uploading}
              >
                <Box sx={{ display: "flex", direction: "row", gap: 1 }}>
                  {uploading && <CircularProgress size={"20px"} />}
                  <Typography variant="body1">Save Images</Typography>
                </Box>
              </Button>
            </Grid>
            {images.map((image, index) => (
              <Grid item xs={6} key={index}>
                <Paper sx={{ textAlign: "center", color: "text.secondary" }}>
                  <img
                    src={image.data}
                    alt={`Uploaded ${index + 1}`}
                    style={{ width: "100%" }}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Uploader;
