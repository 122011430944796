import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  styled,
  Paper,
  CssBaseline,
} from "@mui/material";
import Footer from "../Footer/Footer";
import founder_01 from "../../../assets/images/dwane.jpg";
import founder_02 from "../../../assets/images/kishani.jpg";
import founder_03 from "../../../assets/images/steve.jpg";
import advisor_01 from "../../../assets/images/bill.jpg";
import NavBar from "../LandingComponent/NavBar";

const StyledBox = styled(Paper)({
  backgroundColor: "#e1e1e1",
});

const founders = [
  {
    name: "Dwayne Piyathilake",
    title: "Co-Founder",
    bio: "Award winning, Stained glass designer and studio owner.",
    image: founder_01, // replace with actual image URL
  },
  {
    name: "Kishani Piyathilake",
    title: "Co-Founder",
    bio: "Ex KLM Royal Dutch Airlines, Cargo manager with expertise in logistics & operations.",
    image: founder_02, // replace with actual image URL
  },
  {
    name: "Stephen Le",
    title: "Co-Founder",
    bio: "8 years US Navy veteran. A technology venture capitalist with expertise in AI and E commerce.",
    image: founder_03, // replace with actual image URL
  },

  // Add more founders as needed
];

const advisor = {
  name: "Bill Klopsch",
  title: "Advisor",
  bio: "Bill is the owner of Bill Klopsch Stained Glass in Chicago, an accredited professional member of the Stained Glass Association of America.  After a combat tour in Vietnam as a carrier-based Navy aviator, he found the stained glass profession and has practice the medium for nearly 50 years. Bill served on the board of directors for the SGAA for two terms and wrote over fifty articles for The Stained Glass News. His focus is the repair and restoration of leaded and Stained Glass",
  image: advisor_01, // replace with actual image URL
};

const Founders = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StyledBox>
        <NavBar />
        <CssBaseline />

        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          align="center"
          color="#0099ff"
          sx={{ my: 3 }}
        >
          Meet Our Founders
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {founders.map((founder, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card sx={{ maxWidth: 375, margin: "auto" }}>
                <CardMedia
                  component="img"
                  height="300"
                  image={founder.image}
                  alt={founder.name}
                  sx={{ objectFit: "contain", my: 3 }}
                />
                <CardContent sx={{ backgroundColor: "#f7f7f7" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ textAlign: "center" }}
                  >
                    {founder.name}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {founder.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                      textAlign: "justify",
                    }}
                  >
                    {founder.bio}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mt: 4,
          }}
        >
          <Card sx={{ maxWidth: { xs: 375, md: 500 }, margin: "auto" }}>
            <CardMedia
              component="img"
              image={advisor.image}
              alt={advisor.name}
              sx={{
                objectFit: "contain",
                my: 3,
                height: { xs: 300, md: 400 },
              }}
            />
            <CardContent sx={{ backgroundColor: "#f7f7f7" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ textAlign: "center" }}
              >
                {advisor.name}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {advisor.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontFamily: "Montserrat, sans-serif",
                  textAlign: "justify",
                }}
              >
                {advisor.bio}
              </Typography>
            </CardContent>
          </Card>
        </Box>

        <Footer />
      </StyledBox>
    </>
  );
};

export default Founders;
