import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import { ReviewRequest } from "../../../utils/interfaces";
import ImageIcon from "@mui/icons-material/Image";

interface ChildProps {
  church: ReviewRequest;
  handler: (church: ReviewRequest) => void;
}

export const AnnotationCard: React.FC<ChildProps> = ({ church, handler }) => {
  const newImageCount = church.unAnnotatedImages;
  return (
    <Card
      sx={{
        width: { xs: "80vw", sm: 500 },
        height: 185,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: { xs: 1, sm: 3 },
        }}
      >
        <Box
          component="img"
          src={church.images[0]}
          alt="random"
          sx={{
            width: 150,
            height: 150,
            objectFit: "cover",
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyItems: "start",
                alignItems: "start",
              }}
            >
              <Typography
                variant="h5"
                color="text.primary"
                sx={{ fontWeight: 600 }}
              >
                {church.name}
              </Typography>
              <Typography
                variant="caption"
                color="text.primary"
                sx={{ fontWeight: 500 }}
              >
                Requested by: {church.manager}
              </Typography>
              <Typography variant="caption" color="text.primary">
                Status: {church.status}
              </Typography>
            </Box>
            <Badge color="error" badgeContent={newImageCount}>
              <ImageIcon fontSize="large" />
            </Badge>
          </Box>

          <Button
            onClick={() => {
              handler(church);
            }}
            variant="contained"
            size="small"
            color="primary"
            sx={{
              color: "white",
              borderRadius: "20px",
              width: "150px",
              mt: 2,
            }}
          >
            Create Analytics
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AnnotationCard;
