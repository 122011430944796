import AdminHomeComponent from "../components/admin/Home";
import MEGalleryComponent from "../components/church_maintenance_engineer/GalleryComponent";
import ChurchManintenanceEngineerHomeComponent from "../components/church_maintenance_engineer/Home";
import UploadImagesComponent from "../components/church_maintenance_engineer/uploadImages/UploadImages";
import CommentsComponent from "../components/church_manager/Comments";
import GalleryComponent from "../components/church_manager/Gallery";
import ChurchManagerHomeComponent from "../components/church_manager/Home/Home";
import MaintenanceEngineersComponent from "../components/church_manager/Maintenance_Engineers";
import DroneOperatorHomeComponent from "../components/drone_operator/Home";
import ManagerCreateAnnotations from "../components/manager/Annotation/Annotation";
import ManagerHomeComponent from "../components/manager/Home/Home";
import BMReviewRequestsComponent from "../components/manager/ReviewRequest/ReviewRequest";
import { ViewDronImages } from "../components/manager/Annotation/ViewDronImages";
import { ProtectedRoute } from "./protectedRoute";
import DroneOperatorAssignComponent from "../components/manager/DroneOperators/DroneOperatorAssignComponent";

import PaymentSuccess from "../components/church_manager/PaymentSuccess";
import PaymentCancel from "../components/church_manager/PaymentCancel";

const routeComponents = {
  adminHome: () => {
    return (
      <ProtectedRoute>
        <AdminHomeComponent />
      </ProtectedRoute>
    );
  },
  churchManagerHome: () => {
    return (
      <ProtectedRoute>
        <ChurchManagerHomeComponent />
      </ProtectedRoute>
    );
  },
  comments: () => {
    return (
      <ProtectedRoute>
        <CommentsComponent />
      </ProtectedRoute>
    );
  },
  gallery: () => {
    return (
      <ProtectedRoute>
        <GalleryComponent />
      </ProtectedRoute>
    );
  },
  maintananceEngineers: () => {
    return (
      <ProtectedRoute>
        <MaintenanceEngineersComponent />
      </ProtectedRoute>
    );
  },
  paymentSuccess: () => {
    return (
      <ProtectedRoute>
        <PaymentSuccess />
      </ProtectedRoute>
    );
  },
  paymentCancel: () => {
    return (
      <ProtectedRoute>
        <PaymentCancel />
      </ProtectedRoute>
    );
  },
  businessManagerHome: () => {
    return (
      <ProtectedRoute>
        <ManagerHomeComponent />
      </ProtectedRoute>
    );
  },
  businessManagerReviewRequest: () => {
    return (
      <ProtectedRoute>
        <BMReviewRequestsComponent />
      </ProtectedRoute>
    );
  },
  businessManagerViewDroneImages: () => {
    return (
      <ProtectedRoute>
        <ViewDronImages />
      </ProtectedRoute>
    );
  },
  businessManagerCreateAnnotations: () => {
    return (
      <ProtectedRoute>
        <ManagerCreateAnnotations />
      </ProtectedRoute>
    );
  },
  businessManagerDroneOperatorAssign: () => {
    return (
      <ProtectedRoute>
        <DroneOperatorAssignComponent />
      </ProtectedRoute>
    );
  },
  droneOperatorHome: () => {
    return (
      <ProtectedRoute>
        <DroneOperatorHomeComponent />
      </ProtectedRoute>
    );
  },
  churchMaintenanceEngineerHome: () => {
    return (
      <ProtectedRoute>
        <ChurchManintenanceEngineerHomeComponent />
      </ProtectedRoute>
    );
  },
  uploadImages: () => {
    return (
      <ProtectedRoute>
        <UploadImagesComponent />
      </ProtectedRoute>
    );
  },
  mEGallery: () => {
    return (
      <ProtectedRoute>
        <MEGalleryComponent />
      </ProtectedRoute>
    );
  },
};

export default routeComponents;
