import {
  Toolbar,
  Box,
  Typography,
  Button,
  styled,
  AppBar,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { APP_NAME, WORKSHOP_ENGINEER, SIGNUP, LOGIN } from "../../../constants";
import logo from "../../../assets/images/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";

const StyledAppBar = styled(AppBar)({
  background: "linear-gradient(to right, #ffffff, #0099ff)",
});

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export const NavBar = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const drawerWidth = 240;
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const navItems = ["WORKSHOPS", "Sign UP", "Log In"];
  const paths = [`/${WORKSHOP_ENGINEER}`, `/${SIGNUP}`, `/${LOGIN}`];
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {APP_NAME}
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={item} disablePadding>
            <ListItemButton href={paths[index]} sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <StyledAppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <Button color="inherit" component={NavLink} to="/">
              <Box
                component="img"
                src={logo}
                alt="random"
                sx={{
                  width: "50px",
                  height: "auto",
                  pr: 2,
                }}
              />
              <Typography
                variant="h4"
                sx={{ color: "black", fontFamily: "DM Serif Display, serif" }}
              >
                {APP_NAME}
              </Typography>
            </Button>
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              color="inherit"
              component={NavLink}
              to={`/${WORKSHOP_ENGINEER}`}
            >
              WORKSHOPS
            </Button>
            <Button color="inherit" component={NavLink} to={`/${SIGNUP}`}>
              SignUp
            </Button>
            <Button color="inherit" component={NavLink} to={`/${LOGIN}`}>
              Login
            </Button>
          </Box>
        </Toolbar>
      </StyledAppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
};

export default NavBar;
