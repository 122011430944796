import { Box, Button, Typography } from "@mui/material";
import church_request from "../../../assets/images/church_req.png";
import drone_operators from "../../../assets/images/Drone Operator 1.png";
// import drone_operators from "../../../assets/images/drone_op5.png";
import church_comments from "../../../assets/images/church_comments.png";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../ThemeProviderWrapper";

import {
  BM_DRONE_OPERATOR_ASSIGN,
  BM_REVIEW_REQUESTS,
  VIEW_DRONE_IMAGES,
} from "../../../constants";
import { useEffect } from "react";

export default function ManagerHomeComponent() {
  const navigate = useNavigate();
  const { isDarkMode, toggleTheme } = useTheme();

  useEffect(() => {
    if (isDarkMode) {
      toggleTheme();
    }
  }, [isDarkMode, toggleTheme]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        sx={{
          width: 350,
          height: 350,
          margin: 3,
          borderRadius: 25,
          padding: 0,
          backgroundColor: "white",
          "&:hover": { backgroundColor: "white" },
        }}
        variant="contained"
        onClick={() => navigate(BM_REVIEW_REQUESTS)}
      >
        <Box
          component="img"
          src={church_request}
          alt="random"
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
      </Button>

      <Button
        sx={{
          width: 350,
          height: 350,
          margin: 3,
          borderRadius: 25,
          padding: 0,
          backgroundColor: "white",
          "&:hover": { backgroundColor: "white" },
        }}
        variant="contained"
        color="info"
        onClick={() => navigate(BM_DRONE_OPERATOR_ASSIGN)} // Handle button click event
      >
        <Box
          component="img"
          src={drone_operators}
          alt="random"
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 25,
          }}
        />
      </Button>

      <Button
        sx={{
          width: 350,
          height: 350,
          margin: 3,
          borderRadius: 25,
          padding: 0,
          backgroundColor: "white",
          "&:hover": { backgroundColor: "white" },
        }}
        variant="contained"
        color="info"
        onClick={() => navigate(VIEW_DRONE_IMAGES)} // Handle button click event
      >
        <Box
          component="img"
          src={church_comments}
          alt="random"
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
      </Button>
    </Box>
  );
}
