import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';

export default function PaymentCancel() {

  useEffect(() => {
    toast.error('Payment cancelled');
    setTimeout(() => {
      window.location.href = '/church-manager';
    }, 1500);
  }, []);

  return (
    <div>
      <ToastContainer />
      <CircularProgress color="secondary" />
    </div>
  );
}
