import { createTheme } from "@mui/material/styles";

// Dark theme configuration
const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#263238",
      light: "#4f5b62",
      dark: "#000a12",
    },
    secondary: {
      main: "#ff9800",
      light: "#ffc947",
      dark: "#c66900",
    },
    background: {
      default: "#0d0f12",
      paper: "#1b1e24",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b0bec5",
      disabled: "#757575",
    },
    divider: "#424242",
    action: {
      active: "#ffffff",
      hover: "#ffa726",
      selected: "#ff9800",
      disabled: "#757575",
      disabledBackground: "#b0bec5",
    },
    success: {
      main: "#00c853",
      light: "#69f0ae",
      dark: "#00b248",
    },
    warning: {
      main: "#ff9800",
      light: "#ffc947",
      dark: "#c66900",
    },
    error: {
      main: "#ff3d00",
      light: "#ff7539",
      dark: "#c30000",
    },
    info: {
      main: "#ffffff",
      light: "#ffffff",
      dark: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    button: {
      fontSize: "0.875rem",
      fontWeight: 500,
      textTransform: "none",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    overline: {
      fontSize: "0.625rem",
      fontWeight: 500,
      textTransform: "uppercase",
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#9e9e9e",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            boxSizing: "border-box",
            minWidth: 280,
            minHeight: 40,
            height: "100%",
            borderRadius: "10px",
            border: "1px solid",
            borderColor: "#ffffff",
            transition: "border-color 120ms ease-in",
            "& fieldset": {
              border: "none",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            },
            "&:hover": {
              borderColor: "#ff9800",
            },
            "&.Mui-focused": {
              borderColor: "#b96e00",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          borderRadius: "4px",
          "& $notchedOutline": {
            borderColor: "#424242",
          },
          "&:hover $notchedOutline": {
            borderColor: "#ffffff",
          },
          "&$focused $notchedOutline": {
            borderColor: "#ffffff",
          },
        },
        notchedOutline: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
        },
        containedPrimary: {
          backgroundColor: "#ff9800",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#ffa726",
          },
        },
        outlinedPrimary: {
          borderColor: "#ff9800",
          color: "#ff9800",
          "&:hover": {
            backgroundColor: "#ff9800",
            color: "#ffffff",
          },
        },
        textPrimary: {
          color: "#ff9800",
          "&:hover": {
            backgroundColor: "#424242",
            color: "#ffffff",
          },
        },
        containedSecondary: {
          backgroundColor: "#263238",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#4f5b62",
          },
        },
        outlinedSecondary: {
          borderColor: "#263238",
          color: "#263238",
          "&:hover": {
            backgroundColor: "#263238",
            color: "#ffffff",
          },
        },
        textSecondary: {
          color: "#263238",
          "&:hover": {
            backgroundColor: "#ffffff",
            color: "#263238",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          "&.Mui-focused": {
            color: "#ffffff",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: "0.75rem",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#4f5b62",
          border: "1px solid #424242",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: "#424242",
        },
        head: {
          color: "#ffffff",
          backgroundColor: "#ff9800",
        },
        body: {
          color: "#b0bec5",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#424242",
          },
        },
        head: {
          "&:hover": {
            backgroundColor: "#4f5b62",
          },
        },
        hover: {
          "&:hover": {
            backgroundColor: "#424242",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: {
          color: "#ff9800",
          fontWeight: 500,
          position: "relative",
          textDecoration: "none",
          "&::before": {
            content: '""',
            position: "absolute",
            width: 0,
            height: "1px",
            bottom: 0,
            left: 0,
            opacity: 0.7,
            backgroundColor: "#ff9800",
            transition: "width 0.3s ease, opacity 0.3s ease",
          },
          "&:hover::before": {
            width: "100%",
            opacity: 1,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: "#ff9800",
        },
      },
    },
  },
});

// Light theme configuration
const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
      light: "#757de8",
      dark: "#002984",
    },
    secondary: {
      main: "#f50057",
      light: "#ff4081",
      dark: "#c51162",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#424242",
      disabled: "#bdbdbd",
    },
    divider: "#e0e0e0",
    action: {
      active: "#000000",
      hover: "#f50057",
      selected: "#f50057",
      disabled: "#bdbdbd",
      disabledBackground: "#f5f5f5",
    },
    success: {
      main: "#4caf50",
      light: "#81c784",
      dark: "#388e3c",
    },
    warning: {
      main: "#ff9800",
      light: "#ffb74d",
      dark: "#f57c00",
    },
    error: {
      main: "#f44336",
      light: "#e57373",
      dark: "#d32f2f",
    },
    info: {
      main: "#2196f3",
      light: "#64b5f6",
      dark: "#1976d2",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    button: {
      fontSize: "0.875rem",
      fontWeight: 500,
      textTransform: "none",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    overline: {
      fontSize: "0.625rem",
      fontWeight: 500,
      textTransform: "uppercase",
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#000000",
          "&:hover": {
            backgroundColor: "#e0e0e0",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            boxSizing: "border-box",
            minWidth: 280,
            minHeight: 40,
            height: "100%",
            borderRadius: "10px",
            border: "1px solid",
            borderColor: "#000000",
            transition: "border-color 120ms ease-in",
            "& fieldset": {
              border: "none",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            },
            "&:hover": {
              borderColor: "#f50057",
            },
            "&.Mui-focused": {
              borderColor: "#c51162",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#000000",
          borderRadius: "4px",
          "& $notchedOutline": {
            borderColor: "#e0e0e0",
          },
          "&:hover $notchedOutline": {
            borderColor: "#000000",
          },
          "&$focused $notchedOutline": {
            borderColor: "#000000",
          },
        },
        notchedOutline: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
        },
        containedPrimary: {
          backgroundColor: "#3f51b5",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#757de8",
          },
        },
        outlinedPrimary: {
          borderColor: "#3f51b5",
          color: "#3f51b5",
          "&:hover": {
            backgroundColor: "#3f51b5",
            color: "#ffffff",
          },
        },
        textPrimary: {
          color: "#3f51b5",
          "&:hover": {
            backgroundColor: "#e0e0e0",
            color: "#3f51b5",
          },
        },
        containedSecondary: {
          backgroundColor: "#f50057",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#ff4081",
          },
        },
        outlinedSecondary: {
          borderColor: "#f50057",
          color: "#f50057",
          "&:hover": {
            backgroundColor: "#f50057",
            color: "#ffffff",
          },
        },
        textSecondary: {
          color: "#f50057",
          "&:hover": {
            backgroundColor: "#ffffff",
            color: "#f50057",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#000000",
          "&.Mui-focused": {
            color: "#000000",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: "0.75rem",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          border: "1px solid #e0e0e0",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: "#e0e0e0",
        },
        head: {
          color: "#000000",
          backgroundColor: "#f50057",
        },
        body: {
          color: "#424242",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#e0e0e0",
          },
        },
        head: {
          "&:hover": {
            backgroundColor: "#ffffff",
          },
        },
        hover: {
          "&:hover": {
            backgroundColor: "#e0e0e0",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#000000",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: {
          color: "#f50057",
          fontWeight: 500,
          position: "relative",
          textDecoration: "none",
          "&::before": {
            content: '""',
            position: "absolute",
            width: 0,
            height: "1px",
            bottom: 0,
            left: 0,
            opacity: 0.7,
            backgroundColor: "#f50057",
            transition: "width 0.3s ease, opacity 0.3s ease",
          },
          "&:hover::before": {
            width: "100%",
            opacity: 1,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: "#f50057",
        },
      },
    },
  },
});

export { darkTheme, lightTheme };
