import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import church from "../../../assets/images/church_req.webp";
import workshop_engineer from "../../../assets/images/workshop_engineer.webp";

import { getCities, getStates } from "../../../utils/commonFunctions";
import { City } from "../../../utils/interfaces";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import { BACKEND_APP_URL } from "../../../constants";
import { useAuth } from "../../../hooks/useAuth";
import { toast } from "react-toastify";

export function AdsSection() {
  const auth = useAuth();
  const user = auth.user;
  const [workshopEngineers, setWorkshopEngineers] = useState([]);
  const USAStates = [{ value: "all", label: "All" }, ...getStates()];
  const [USAcities, setUSAcities] = useState<City[]>([]);
  const [city, setCity] = useState("all");
  const [state, setState] = useState("all");
  const [filteredWorkshopEngineers, setFilteredWorkshopEngineers] = useState(
    []
  );
  const [start, setStart] = useState(0);
  const addsPerPage = 6;
  const [selectedAdds, setSelectedAdds] = useState([]);

  const handlePagination = (event: any, value: number) => {
    setStart((value - 1) * addsPerPage);
  };

  const fetchWorkshopEngineers = async () => {
    try {
      const response = await performRequestWithRetry(
        `${BACKEND_APP_URL}/api/workshop-engineers`,
        "GET",
        null,
        user,
        auth.logout
      );
      setWorkshopEngineers(response?.data);
      setFilteredWorkshopEngineers(response?.data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch workshop engineers");
    }
  };

  useEffect(() => {
    fetchWorkshopEngineers();
  }, []);

  useEffect(() => {
    if (filteredWorkshopEngineers && filteredWorkshopEngineers.length > 0) {
      setSelectedAdds(
        filteredWorkshopEngineers.slice(start, start + addsPerPage)
      );
    }
  }, [start, filteredWorkshopEngineers]);

  useEffect(() => {
    const cities = getCities(state);
    setUSAcities([{ value: "all", label: "All" }, ...cities]);
  }, [state]);

  setInterval(() => {
    // shuffle the adds in filteredWorkshopEngineers list if state is all and city is all
    if (city === "all" && state === "all") {
      setFilteredWorkshopEngineers(
        filteredWorkshopEngineers.sort(() => Math.random() - 0.5)
      );
    }
  }, 1000 * 60);

  useEffect(() => {
    if (city === "all" && state === "all") {
      setFilteredWorkshopEngineers(workshopEngineers);
    } else if (city === "all") {
      setFilteredWorkshopEngineers(
        workshopEngineers.filter((x: any) => x.state === state)
      );
    } else if (state === "all") {
      setFilteredWorkshopEngineers(
        workshopEngineers.filter((x: any) => x.city === city)
      );
    } else {
      setFilteredWorkshopEngineers(
        workshopEngineers.filter(
          (x: any) => x.city === city && x.state === state
        )
      );
    }
  }, [state, city, workshopEngineers]);

  return (
    <>
      <Box sx={{ mt: 5, mb: 10 }}>
        <Typography variant="h4" color="text.primary" sx={{ fontWeight: 600 }}>
          Workshop Engineers Near Me
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 8,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 400,
            }}
          >
            <Box
              component="img"
              src={workshop_engineer}
              alt="random"
              sx={{
                width: 350,
                height: 350,
              }}
            />
            <Typography
              variant="body2"
              color="text.primary"
              sx={{ fontWeight: 500, mb: 3 }}
            >
              Find the best workshop engineers near you. Contact them for
              maintenance and repair services.
            </Typography>
            <Card>
              <CardContent>
                <Typography
                  variant="h6"
                  color="text.primary"
                  sx={{ fontWeight: 600 }}
                >
                  Filters
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    m: 2,
                  }}
                >
                  <Autocomplete
                    id="state"
                    options={USAStates}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => {
                      if (value) {
                        setState(value.value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="State" variant="outlined" />
                    )}
                  />

                  <Autocomplete
                    id="city"
                    options={USAcities}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => {
                      if (value) {
                        setCity(value.value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="City" variant="outlined" />
                    )}
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignContent: "flex-start",
              gap: 3,
              m: 2,
            }}
          >
            {selectedAdds &&
              selectedAdds.map((x: any, idx: number) => (
                <Card sx={{ width: 430, height: 150 }} key={idx}>
                  <CardContent
                    sx={{ display: "flex", flexDirection: "row", gap: 3 }}
                  >
                    <Box
                      component="img"
                      src={x.image ? x.image : church}
                      alt="random"
                      sx={{
                        width: 120,
                        height: 120,
                      }}
                    />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="h6"
                        color="text.primary"
                        sx={{ fontWeight: 600 }}
                      >
                        {x.name}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.primary"
                        sx={{ fontWeight: 500 }}
                      >
                        {x.city},{" "}
                        {USAStates.find((y) => y.value === x.state)?.label}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{ fontWeight: 500, mt: 2 }}
                      >
                        {x.email}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{ fontWeight: 500 }}
                      >
                        {x.contact_no}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ))}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <Pagination
            count={Math.ceil(filteredWorkshopEngineers.length / addsPerPage)}
            color="primary"
            onChange={handlePagination}
          />
        </Box>
      </Box>
    </>
  );
}
