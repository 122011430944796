import axios from "axios";
import { BACKEND_APP_URL } from "../constants";
import { toast } from "react-toastify";

export const performRequestWithRetry = async (
  url: string,
  method: string,
  reqBody: any,
  user: any,
  logout: (message?: string) => void
) => {
  try {
    const response = await axios(url, {
      method: method,
      data: reqBody,
      headers: {
        "x-access-token": user.accessToken,
      },
    });
    return response;
  } catch (error: any) {
    console.error("Error in API call", error);
    if (
      error.response &&
      error.response.data.message === "Unauthorized!" &&
      error.response.status === 401
    ) {
      // Access token may be expired. Try to refresh the tokens.
      try {
        const refreshResponse: any = await axios.post(
          `${BACKEND_APP_URL}/api/auth/refreshtoken`,
          {
            refreshToken: user.refreshToken,
          }
        );
        if (refreshResponse.status === 200) {
          const newAccessToken = refreshResponse.data.accessToken;
          console.log("New access token", newAccessToken);
          user = { ...user, accessToken: newAccessToken };
          // update user object in local storage
          localStorage.setItem("user", JSON.stringify(user));
          // Token refresh successful. Retry the API call.
          const retryResponse = await axios(url, {
            headers: {
              "x-access-token": newAccessToken,
            },
          });
          return retryResponse;
        } else {
          console.log("status", refreshResponse.status);
          console.error("Error refreshing token", refreshResponse);

          localStorage.removeItem("user");
          return null;
        }
      } catch (refreshError: any) {
        if (
          refreshError.response &&
          refreshError.response.status === 403 &&
          refreshError.response.data.message ===
            "Refresh Token was expired. Please make a new signin request!"
        ) {
          // Session has expired (i.e., Refresh token has also expired).

          console.log(
            "Failed to refresh token. Status: " + refreshError.response.status
          );
          localStorage.removeItem("user");
          toast.error("Session expired. Please login again");
          setTimeout(() => {
            logout("Session expired. Please login again");
          }, 2000);
          return null;
        } else {
          // We can't refresh the token due to a server error.
          // Hence just throw the original 401 error from the API.
          throw error;
          // localStorage.removeItem("user");
          // toast.error("Failed to perform operation");
          // // logout("Session expired. Please login again");
          // return null;
        }
      }
    } else {
      console.error("Error in API call", error);
      toast.error("Failed to perform operation");
    }
  }
};
