import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { BACKEND_APP_URL } from "../../constants";
import { CircularProgress } from "@mui/material";
import { performRequestWithRetry } from "../../utils/apiRetry";

// const sessionID =  from url parameter
// then retrieve stripe session details using sessionID
// then get the payment intent id from the session details and check the payment status

export default function PaymentSuccess() {
  const auth = useAuth();
  const user = auth?.user;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");
    // console.log("sessionId", sessionId);
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/payment/success`,
      "POST",
      {
        sessionId: sessionId,
        userId: user.id,
      },
      user,
      auth.logout
    )
      .then((response) => {
        // console.log("Payment response", response);
        if (response && response.status === 200) {
          toast.success("Payment successful");
          // navigate to home
          setTimeout(() => {
            window.location.href = "/church-manager";
          }, 500);
        }
      })
      .catch((error) => {
        console.error("Payment success error", error);
        toast.error("Payment failed");
        // navigate to home
        setTimeout(() => {
          window.location.href = "/church-manager";
        }, 500);
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ToastContainer />
      <CircularProgress color="secondary" />
    </div>
  );
}
