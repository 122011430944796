import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import church from "../../assets/images/church_req.webp";
import { AddPhotoAlternate } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import { performRequestWithRetry } from "../../utils/apiRetry";
import { BACKEND_APP_URL } from "../../constants";
import { useAuth } from "../../hooks/useAuth";

export default function DroneOperatorHomeComponent() {
  const [requests, setRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState<{ data: string; name: string }[]>([]);
  const [selectedRequestId, setSelectedRequestId] = useState("");

  const auth = useAuth();
  const user = auth?.user;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newImages: { data: string; name: string }[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = () => {
          if (typeof reader.result === "string") {
            newImages.push({ data: reader.result, name: file.name });
            if (newImages.length === files.length) {
              setImages((prevImages) => [...prevImages, ...newImages]);
            }
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSaveImages = () => {
    const reqBody = {
      images: images,
    };
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/drone-operator/images/${selectedRequestId}`,
      "POST",
      reqBody,
      user,
      auth.logout
    )
      .then((data) => {
        toast.success("Images saved successfully", {
          theme: "dark",
        });
        setImages([]);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to save images", {
          theme: "colored",
        });
      });
    setSelectedRequestId("");
    setOpen(false);
  };

  useEffect(() => {
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/drone-operator/requests/${user.id}`,
      "GET",
      null,
      user,
      auth.logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          setRequests(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to fetch requests", {
          theme: "colored",
        });
      });
  }, []);

  return (
    <>
      <ToastContainer />
      <Dialog
        open={open}
        onClose={() => {
          setImages([]);
          setOpen(false);
        }}
      >
        <DialogTitle>Upload Images</DialogTitle>
        <Box sx={{ m: 2 }}>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  sx={{ p: 2, textAlign: "center", color: "text.secondary" }}
                >
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleImageChange}
                  />
                  <label htmlFor="contained-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <AddPhotoAlternate />
                    </IconButton>
                    <Typography variant="body1">Upload Images</Typography>
                  </label>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveImages}
                  fullWidth
                  disabled={images.length === 0}
                >
                  SAVE IMAGES TO GALLERY
                </Button>
              </Grid>
              {images.map((image, index) => (
                <Grid item xs={6} key={index}>
                  <Paper sx={{ textAlign: "center", color: "text.secondary" }}>
                    <img
                      src={image.data}
                      alt={`Uploaded ${index + 1}`}
                      style={{ width: "100%" }}
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Dialog>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Assigned Jobs</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            {requests.map((request: any, idx: number) => (
              <Grid item key={idx} xs={4}>
                <Card>
                  <CardContent
                    sx={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Box
                      component="img"
                      src={request?.images ? request?.images[0] : church}
                      alt="random"
                      sx={{
                        maxWidth: 150,
                        maxHeight: 150,
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Typography
                        variant="h5"
                        color="text.primary"
                        sx={{ fontWeight: 600 }}
                      >
                        {request?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{ fontWeight: 500 }}
                      >
                        {request?.email}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.primary"
                        sx={{ fontWeight: 500 }}
                      >
                        {request?.address}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: 150, mt: 3 }}
                        onClick={() => {
                          setSelectedRequestId(request._id);
                          setOpen(true);
                        }}
                      >
                        Upload Images
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
