import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";

const CreatedFolders = ({
  folders,
  setFolderName,
  setFold,
}: {
  folders: any[];
  setFolderName: (value: string) => void;
  setFold: (value: boolean) => void;
}) => {
  return (
    <List>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {folders.map((folder: any) => (
          <Grid item xs={12} md={6} key={folder.id}>
            <ListItem
              key={folder.id}
              onClick={() => {
                setFolderName(folder.name);
                setFold(true);
              }}
              sx={{
                border: "1px solid #ddd",
                borderRadius: "4px",
                marginBottom: "8px",
                cursor: "pointer",
              }}
            >
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary={folder.name} />
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </List>
  );
};

export default CreatedFolders;
