import { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Link,
  Typography,
  InputAdornment,
  IconButton,
  CssBaseline,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { APP_NAME, BACKEND_APP_URL, SIGNUP } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import bg2 from "../../../assets/images/bg2.webp";
import axios from "axios";
import { useAuth } from "../../../hooks/useAuth";
import { useTheme } from "../../../ThemeProviderWrapper";
import NavBar from "../LandingComponent/NavBar";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 30,
  borderRadius: "10px",
  background: "linear-gradient(to right, #4f5b62, #1a1a1a)",
});

const TextFieldStyled = styled(TextField)({
  marginTop: "10px",
  marginBottom: "10px",
});

const ButtonStyled = styled(Button)({
  marginTop: "20px",
  width: "100%",
  background: "linear-gradient(to right, #ffc051, #c66900)",
  borderRadius: 10,
});

function Login() {
  const { isDarkMode, toggleTheme } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { login, user } = useAuth();

  const sessionExpired =
    location.state &&
    location.state.detail === "Session Expired. Please login again";

  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (sessionExpired) {
      setError("Session Expired. Please login again");
      toast.error("Session Expired. Please login again", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
      });
    }
  }, [sessionExpired]);

  useEffect(() => {
    if (user) {
      login(user);
    } else {
      if (!isDarkMode) {
        toggleTheme();
      }
    }
  }, [user, navigate]);

  const handleUsernameChange = (event: any) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // API call to authenticate user
    try {
      const response = await axios.post(`${BACKEND_APP_URL}/api/auth/signin`, {
        email: username,
        password: password,
      });

      // Handle successful login
      if (response.status === 200) {
        login(response.data);
      } else {
        setError("Invalid email or password");
      }
    } catch (error) {
      // Handle login error
      console.error(error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <>
      <NavBar />
      <CssBaseline />
      <Box
        sx={{
          backgroundImage: `url(${bg2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ToastContainer />
        <FormContainer
          sx={{
            width: { xs: "80vw", sm: "30vw" },
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            sx={{ fontWeight: "700" }}
          >
            {APP_NAME} - Login
          </Typography>
          {error && (
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          )}
          <form onSubmit={handleSubmit}>
            <TextFieldStyled
              label="Email address"
              type="email"
              value={username}
              onChange={handleUsernameChange}
              variant="outlined"
              fullWidth
            />
            <TextFieldStyled
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={password}
              onChange={handlePasswordChange}
              variant="outlined"
              fullWidth
            />
            <ButtonStyled
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              // disabled={true}Y
            >
              Login
            </ButtonStyled>
          </form>
          {/* <Link href={`/${FORGOTPASSWORD}`}>Forgot password?</Link> */}
          <Typography variant="body2" sx={{ mt: 2 }}>
            Don't have an account? <Link href={`/${SIGNUP}`}>Sign Up</Link>
          </Typography>
        </FormContainer>
      </Box>
    </>
  );
}

export default Login;
