import { useState, useEffect } from "react";
import { ReviewRequest } from "../../../utils/interfaces";
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Pagination,
} from "@mui/material";
import AnnotationCard from "./AnnotationCard";
import { useNavigate } from "react-router-dom";
import {
  BACKEND_APP_URL,
  CREATE_ANNOTAIONS,
  MANAGER,
} from "../../../constants";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import { useAuth } from "../../../hooks/useAuth";

export const ViewDronImages = () => {
  const churchePerPage = 6;
  const navigate = useNavigate();
  const [churches, setChurches] = useState<ReviewRequest[]>([]);
  const [selectedChurches, setSelectedChurches] = useState<ReviewRequest[]>([]);
  const [loading, setLoading] = useState(false);

  const auth = useAuth();
  const user = auth?.user;

  useEffect(() => {
    // fetch church details for annotations
    // ToDo: change the endpoint to fetch the church details with progress (percentage of images annotated) @Akila-I
    // look at the changed interface for ReviewRequest @Akila-I
    setLoading(true);
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/business-manager/requests/assigned`,
      "GET",
      null,
      user,
      auth.logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          setChurches(
            response.data.sort((a: ReviewRequest, b: ReviewRequest) =>
              new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
            )
          );
          setSelectedChurches(
            response.data
              .slice(0, churchePerPage)
              .sort((a: ReviewRequest, b: ReviewRequest) =>
                new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
              )
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching review requests", error);
        setLoading(false);
      });
  }, []);

  const handleCreateComment = (church: ReviewRequest) => {
    navigate(`/${MANAGER}/${CREATE_ANNOTAIONS}`, {
      state: { church },
    });
  };

  const handlePageChange = (event: any, value: number) => {
    const start = (value - 1) * churchePerPage;
    const end = start + churchePerPage;
    setSelectedChurches(churches.slice(start, end));
  };

  return (
    <>
      <Typography variant="h5">Image Analytics</Typography>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="start"
      >
        <Grid item>{loading && <CircularProgress color="secondary" />}</Grid>
        <Grid item>
          {!loading && churches.length === 0 && (
            <Typography variant="h5">No Tasks to Analytics</Typography>
          )}
        </Grid>
        <Grid item>
          <Box
            sx={{
              m: 2,
              display: { xs: "none", lg: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {!loading &&
                selectedChurches.map((req: any, index) => {
                  if (index % 2 == 0) {
                    return (
                      <AnnotationCard
                        key={index}
                        church={req}
                        handler={(church) => {
                          handleCreateComment(church);
                        }}
                      />
                    );
                  }
                })}
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              m: 2,
              display: { xs: "none", lg: "flex" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {!loading &&
                selectedChurches.map((req: any, index) => {
                  if (index % 2 == 1) {
                    return (
                      <AnnotationCard
                        key={index}
                        church={req}
                        handler={(church) => {
                          handleCreateComment(church);
                        }}
                      />
                    );
                  }
                })}
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              m: 2,
              display: { xs: "flex", lg: "none" },
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {!loading &&
                selectedChurches.map((req: any, index) => {
                  return (
                    <AnnotationCard
                      key={index}
                      church={req}
                      handler={(church) => {
                        handleCreateComment(church);
                      }}
                    />
                  );
                })}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {!loading && churches.length !== 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
          <Pagination
            count={Math.ceil(churches.length / churchePerPage)}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </>
  );
};
