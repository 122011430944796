import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { BACKEND_APP_URL } from "../../constants";
import { useAuth } from "../../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { performRequestWithRetry } from "../../utils/apiRetry";
import FolderIcon from "@mui/icons-material/Folder";

export default function GalleryComponent() {
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const logout = auth.logout;
  const user = auth?.user;
  const [folders, setFolders] = useState([] as any);
  const [folderName, setFolderName] = useState("");
  const [fold, setFold] = useState(false);

  useEffect(() => {
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/church-manager/gallery/${user.id}`,
      "GET",
      null,
      user,
      logout
    )
      .then((response) => {
        const folderList = response?.data.map((folder: any) => {
          return {
            id: folder._id,
            name: folder.folder_name,
            images: folder.images,
          };
        });
        setFolders(folderList);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSelectFolder = (name: string) => {
    setFolderName(name);
    setItemData(folders.find((folder: any) => folder.name === name).images);
    setFold(true);
  };

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [imageToDelete, setImageToDelete] = useState<any>("");

  const handleDelete = (img: any) => {
    setOpenDialog(true);
    setImageToDelete(img);
  };

  const handleConfirmDelete = () => {
    const manager_id = user.id;
    const image_id = imageToDelete.image_url.split("/").pop();
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/church-manager/gallery/${manager_id}/${folderName}/${image_id}`,
      "DELETE",
      null,
      user,
      logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          toast.success("Image deleted successfully", {
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to delete image", {
          theme: "colored",
        });
      });
    setItemData(itemData.filter((item) => item !== imageToDelete));
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <ToastContainer />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Are you sure you want to delete this image?</DialogTitle>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between", m: 1 }}
        >
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Breadcrumbs>
        <Link color="text.secondary" href="/church-manager">
          <Typography variant="h5">Home</Typography>
        </Link>
        {fold && (
          <Link color="text.secondary" href="/church-manager/gallery">
            <Typography variant="h5">Gallery</Typography>
          </Link>
        )}
        {!fold && (
          <Typography variant="h5" color="text.primary">
            Gallery
          </Typography>
        )}
        {fold && (
          <Typography variant="h5" color="text.primary">
            {folderName}
          </Typography>
        )}
      </Breadcrumbs>

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            mb: 5,
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      )}
      {!loading && !fold && folders && folders.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            mb: 5,
          }}
        >
          <Typography variant="h5" color="text.secondary">
            No Folders found!
          </Typography>
        </Box>
      )}
      {!loading && fold && itemData && itemData.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            mb: 5,
          }}
        >
          <Typography variant="h5" color="text.secondary">
            No images found in {folderName}!
          </Typography>
        </Box>
      )}

      {!loading && !fold && folders && folders.length !== 0 && (
        <Box>
          <List>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {folders.map((folder: any) => (
                <Grid item xs={12} md={6} key={folder.id}>
                  <ListItem
                    key={folder.id}
                    onClick={() => handleSelectFolder(folder.name)}
                    sx={{
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      marginBottom: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={folder.name} />
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </List>
        </Box>
      )}

      {!loading && fold && itemData && itemData.length !== 0 && (
        <Box>
          <ImageList variant="masonry" gap={10}>
            {itemData.map((item: any) => (
              <ImageListItem key={item._id}>
                <img
                  srcSet={`${item.image_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.image_url}?w=248&fit=crop&auto=format`}
                  alt={item.image_name}
                  loading="lazy"
                />
                <IconButton
                  onClick={() => handleDelete(item)}
                  style={{
                    position: "absolute",
                    top: "5px",
                    left: "5px",
                  }}
                  color="secondary"
                >
                  <Close />
                </IconButton>
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}
    </>
  );
}
