import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import workshop_engineer from "../../../assets/images/workshop_engineer.webp";
import { toast, ToastContainer } from "react-toastify";
import { useTheme } from "../../../ThemeProviderWrapper";
import { OngoingSection } from "./OngoingSection";
import { AdsSection } from "./AdsSection";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import { BACKEND_APP_URL } from "../../../constants";
import { useAuth } from "../../../hooks/useAuth";

export default function ChurchManagerHomeComponent() {
  const auth = useAuth();
  const user = auth.user;
  const { isDarkMode, toggleTheme } = useTheme();
  const [data, setData] = useState([] as any[]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const fetchData = async () => {
    setIsDataLoading(true);
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/church-manager/requests/${user?.id}`,
      "GET",
      null,
      user,
      auth.logout
    ).then((response) => {
      const requests = response?.data;
      requests.reverse();
      if (requests.length >= 1) {
        const dummy_request = {
          duration: {
            start: requests[0].duration.start.split("T")[0],
            end: requests[0].duration.end.split("T")[0],
          },
          manager: requests[0].manager,
          status: requests[0].status,
          cost: requests[0].cost,
          address: requests[0].address,
          email: requests[0].email,
          name: requests[0].name,
          images: requests[0].images,
          id: requests[0]._id,
          promotion_code: requests[0].promotion_code,
        };
        if (
          requests[0].status === "Expired" ||
          requests[0].status === "Rejected"
        ) {
          const new_request = {
            duration: {
              start: new Date().toISOString().split("T")[0],
              end: new Date(
                new Date().getFullYear() + 1,
                new Date().getMonth(),
                new Date().getDate()
              )
                .toISOString()
                .split("T")[0],
            },
            manager: user?.first_name + " " + user?.last_name,
            status: "New",
            address: user?.church_address,
            email: user?.email,
            name: user?.church_name,
            id: "",
          };
          let dummy0 = [...data];
          dummy0.push(new_request);
          dummy0.push(dummy_request);
          setData(dummy0);
        } else {
          let dummy = [...data];
          dummy.push(dummy_request);
          setData(dummy);
        }
        setIsDataLoading(false);
      } else {
        const new_request = {
          duration: {
            start: new Date().toISOString().split("T")[0],
            end: new Date(
              new Date().getFullYear() + 1,
              new Date().getMonth(),
              new Date().getDate()
            )
              .toISOString()
              .split("T")[0],
          },
          manager: user?.first_name + " " + user?.last_name,
          status: "New",
          address: user?.church_address,
          email: user?.email,
          name: user?.church_name,
          id: "",
        };
        let dummy = [...data];
        dummy.push(new_request);
        setData(dummy);
        setIsDataLoading(false);
      }
    });
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data");
    }
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      toggleTheme();
    }
  }, [isDarkMode, toggleTheme]);

  return (
    <div>
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <OngoingSection data={data} loading={isDataLoading} />
        {workshop_engineer && <AdsSection />}
      </Box>
    </div>
  );
}
