import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { FOUNDERS } from "../../../constants";
import drone_op1 from "../../../assets/images/process.webp";
import { NavLink } from "react-router-dom";

export const TypographySection = ({ screenWidth }: { screenWidth: number }) => {
  const [showContent, setShowContent] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const fadeIn = useSpring({
    opacity: showContent ? 1 : 0,
    transform: showContent ? "translateX(0)" : "translateX(50px)",
    delay: 300,
  });

  useEffect(() => {
    if (screenWidth < 901) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    if (inView) {
      setShowContent(true);
    }
  }, [inView]);
  return (
    <Box
      sx={{
        py: 4,
        backgroundColor: "#e1e1e1",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          maxWidth: { xs: "100%", md: "90%" },
        }}
        spacing={4}
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          item
          xs={12}
          md={6}
        >
          <Box
            component="img"
            src={drone_op1}
            alt="random2"
            sx={{
              width: "100%",
              borderRadius: "16px",
            }}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          item
          xs={12}
          md={6}
        >
          <>
            {!mobileView ? (
              <animated.div ref={ref} style={fadeIn}>
                <Typography
                  variant="h3"
                  color="#0099ff"
                  sx={{
                    fontWeight: 600,
                    textAlign: {
                      xs: "center",
                      md: "left",
                    },
                  }}
                >
                  About Us
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: 2,

                    textAlign: "justify",
                  }}
                >
                  Glaz AI Inc. is an AI company that preserves the past and
                  protects the future of stained glass using AI technology.
                  Historically, restoration happens when a failure in glass
                  infrastructure occurs ( i.e:breakdown or reactive maintenance)
                  but not anymore. Through our imaging software, we can monitor
                  anomalies that may occur over time in the stained glass window
                  panels which will affect the overall lifespan of the glass
                  structure. Our HQ is based in Chicago with multifaceted
                  talents specializing in stained glass, AI, data science and
                  high-rise glazing. We can save our customers 50%-80% on their
                  stained glass maintenance costs by utilizing our proprietary
                  preventive and predictive maintenance technologies.
                </Typography>
              </animated.div>
            ) : (
              <>
                <Typography
                  variant="h3"
                  color="#0099ff"
                  sx={{
                    fontWeight: 600,
                    textAlign: {
                      xs: "center",
                      md: "left",
                    },
                  }}
                >
                  About Us
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: 2,

                    textAlign: "justify",
                  }}
                >
                  Glaz AI Inc. is an AI company that preserves the past and
                  protects the future of stained glass using AI technology.
                  Historically, restoration happens when a failure in glass
                  infrastructure occurs ( i.e:breakdown or reactive maintenance)
                  but not anymore. Through our imaging software, we can monitor
                  anomalies that may occur over time in the stained glass window
                  panels which will affect the overall lifespan of the glass
                  structure. Our HQ is based in Chicago with multifaceted
                  talents specializing in stained glass, AI, data science and
                  high-rise glazing. We can save our customers 50%-80% on their
                  stained glass maintenance costs by utilizing our proprietary
                  preventive and predictive maintenance technologies.
                </Typography>
              </>
            )}
          </>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              flexDirection: "column",
              mt: 12,
            }}
          >
            <Button
              sx={{ fontSize: "25px" }}
              color="primary"
              variant="contained"
              component={NavLink}
              to={`/${FOUNDERS}`}
            >
              Find more on Founders
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
