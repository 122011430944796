import { Box, Button, Typography } from "@mui/material";
import church_request from "../../assets/images/maintenance engineer upload.png";
import church_comments from "../../assets/images/gallery - church manager.png";
import { useNavigate } from "react-router-dom";
import { GALLERY, UPLOAD_IMAGES } from "../../constants";
import { useTheme } from "../../ThemeProviderWrapper";
import { useEffect } from "react";

export default function ChurchManintenanceEngineerHomeComponent() {
  const navigate = useNavigate();
  const { isDarkMode, toggleTheme } = useTheme();
  useEffect(() => {
    if (isDarkMode) {
      toggleTheme();
    }
  }, [isDarkMode, toggleTheme]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        sx={{
          width: {
            xs: 250,
            sm: 350,
          },
          height: {
            xs: 250,
            sm: 350,
          },
          margin: 3,
          padding: 0,
          backgroundColor: "white",
          borderRadius: 10,
        }}
        variant="contained"
        color="info"
        onClick={() => navigate(UPLOAD_IMAGES)}
      >
        <Box
          component="img"
          src={church_request}
          alt="random"
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 10,
          }}
        />
      </Button>

      <Button
        sx={{
          width: {
            xs: 250,
            sm: 350,
          },
          height: {
            xs: 250,
            sm: 350,
          },
          margin: 3,
          padding: 0,
          backgroundColor: "white",
          borderRadius: 10,
        }}
        variant="contained"
        color="info"
        onClick={() => navigate(GALLERY)}
      >
        <Box
          component="img"
          src={church_comments}
          alt="random"
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 10,
          }}
        />
      </Button>
    </Box>
  );
}
