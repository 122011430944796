import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { ReviewRequest } from "../../../utils/interfaces";
import { Close } from "@mui/icons-material";
import { BACKEND_APP_URL } from "../../../constants";
import { toast, ToastContainer } from "react-toastify";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import { useAuth } from "../../../hooks/useAuth";

interface ChildProps {
  data: ReviewRequest | null;
  open: boolean;
  setOpen: (arg0: boolean) => void;
  setReviewRequestUpdated: (arg0: boolean) => void;
}

const ReveiwRequestForm: React.FC<ChildProps> = ({
  data,
  open,
  setOpen,
  setReviewRequestUpdated,
}) => {
  const [cost, setCost] = useState<number>(0);
  const auth = useAuth();
  const user = auth?.user;

  const handleUpdate = () => {
    // validate that cost is not string and not negative number
    if (isNaN(cost) || cost < 0) {
      toast.error("Invalid cost value");
      return;
    } else {
      const status = cost !== 0 ? "PaymentPending" : "Paid";

      performRequestWithRetry(
        `${BACKEND_APP_URL}/api/business-manager/requests/${data?._id}`,
        "PUT",
        {
          cost: cost,
          status: status,
        },
        user,
        auth.logout
      )
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("Request updated");
            setReviewRequestUpdated(true);
            setOpen(false);
          }
        })
        .catch((error) => {
          console.error("Error updating request", error);
        });
      setOpen(false);
    }
  };

  const handleReject = () => {
    // Reject request
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/business-manager/requests/${data?._id}`,
      "PUT",
      {
        status: "Rejected", // use RERVIEW_REQUEST_STATUS.REJECTED
      },
      user,
      auth.logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          toast.success("Request rejected");
          setReviewRequestUpdated(true);
          setOpen(false);
        }
      })
      .catch((error) => {
        console.error("Error rejecting request", error);
      });
    setOpen(false);
  };

  return (
    <>
      <ToastContainer />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Grid
          container
          justifyContent={"space-between"}
          alignContent={"center"}
          direction={"row"}
        >
          <Grid item>
            <DialogTitle>Create New Review Request</DialogTitle>
          </Grid>
          <Grid item>
            <IconButton
              sx={{ m: 1 }}
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <DialogContent>
          <form>
            <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-name"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Church Name
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={data?.name ? data?.name : "N/A"}
                  disabled
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-address"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Church Address
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={data?.address ? data?.address : "N/A"}
                  disabled
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-email"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Email Address
                </FormLabel>
                <TextField
                  size="small"
                  type="email"
                  defaultValue={data?.email ? data?.email : "N/A"}
                  disabled
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-manager"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Church Manager
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={data?.manager ? data?.manager : "N/A"}
                  disabled
                />
              </FormControl>
              <Divider />
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-duration"
                  sx={{
                    minWidth: 120,
                  }}
                  required
                >
                  Sample Images
                </FormLabel>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignContent: "flex-start",
                    gap: 2,
                  }}
                >
                  {data?.images.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <a
                        key={index}
                        href={image} // Set href to image URL
                        target="_blank" // Open in new tab
                        rel="noopener noreferrer" // Recommended security measure
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          key={index}
                          src={image}
                          alt={`Uploaded ${index}`}
                          style={{ maxWidth: 60, maxHeight: 60 }}
                        />
                      </a>
                    </div>
                  ))}
                </Box>
              </FormControl>

              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-price"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Promotion Code
                </FormLabel>
                <TextField
                  size="small"
                  type={"text"}
                  value={data?.promotion_code}
                  disabled={true}
                />
              </FormControl>

              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-price"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Annual Cost
                </FormLabel>
                <TextField
                  disabled={data?.status !== "Requested"}
                  size="small"
                  type="number"
                  defaultValue={data?.cost ? data?.cost : 0}
                  onChange={(e) => setCost(parseInt(e.target.value))}
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-status"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Status
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={data?.status ? data?.status : "N/A"}
                  disabled
                />
              </FormControl>
              {data && data.status === "Requested" && (
                <>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 2,
                    }}
                  >
                    {/* if data is not null and status is not Rejected Paid, show Reject and Update buttons */}
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleReject}
                    >
                      Reject
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      type="button"
                      // disabled={images.length < 1}
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReveiwRequestForm;
