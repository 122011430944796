import { Box, Grid, Typography } from "@mui/material";

const YouTubeSection = () => {
  return (
    <Box
      sx={{
        py: 4,
        backgroundColor: "#e1e1e1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h3"
        color="#0099ff"
        sx={{ fontWeight: 600, textAlign: { xs: "center", md: "left" }, mb: 4 }}
      >
        What we do?
      </Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{
          maxWidth: { xs: "100%", md: "90%" },
        }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: "relative",
              paddingBottom: "56.25%", // 16:9 aspect ratio
              height: 0,
              overflow: "hidden",
              maxWidth: "100%",
              background: "#000",
              "& iframe": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              },
            }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/2REqtUS4ECQ?si=E9-6FytYub-bwKDq"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default YouTubeSection;
