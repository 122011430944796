import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuItem from "@mui/material/MenuItem";
import {
  ADMIN,
  APP_NAME,
  BACKEND_APP_URL,
  CHURCH_MAINTENANCE_ENGINEER,
  CHURCH_MANAGER,
  DRONE_OPERATOR,
  MANAGER,
  ROLE_ADMIN,
  ROLE_BUSINESSMANAGER,
  ROLE_CHURCHMANAGER,
  ROLE_DRONEOPERATOR,
  ROLE_MAINTENANCEENGINEER,
} from "../../../constants";
import logo from "../../../assets/images/logo.webp";
import {
  Button,
  Dialog,
  Divider,
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import { toast, ToastContainer } from "react-toastify";
import { useTheme } from "../../../ThemeProviderWrapper";
import { useLocation, useNavigate } from "react-router-dom";

const PasswordStrength = ({ strength }: { strength: number }) => {
  const colors = ["#ff5349", "#ffa22b", "#fdd835", "#00e676", "#00b8d4"];
  const labels = ["Very weak", "Weak", "Fair", "Strong", "Very strong"];

  return (
    <Box
      display="flex"
      alignItems="center"
      marginTop="10px"
      marginBottom="5px"
      pl={2}
      pr={2}
    >
      <Box
        height="10px"
        flex={strength}
        borderRadius="4px"
        marginRight="5px"
        sx={{
          backgroundColor: colors[strength - 1],
        }}
      />
      <Typography variant="caption" style={{ color: "#FFF", fontWeight: 300 }}>
        {labels[strength - 1]}
      </Typography>
    </Box>
  );
};

function MainAppBar(user: any) {
  const nav = useNavigate();
  const { isDarkMode, toggleTheme } = useTheme();
  const currentUser = user.user;
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const initialFormData = {
    first_name: currentUser ? currentUser.first_name : "",
    last_name: currentUser ? currentUser.last_name : "",
    cur_password: "",
    new_password: "",
    re_password: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState<string[]>([]);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);

  const location = useLocation();
  console.log(location.pathname);

  const navigate = (user: any) => {
    if (user.role === ROLE_ADMIN) {
      nav("/" + ADMIN);
    } else if (user.role === ROLE_BUSINESSMANAGER) {
      nav("/" + MANAGER);
    } else if (user.role === ROLE_CHURCHMANAGER) {
      nav("/" + CHURCH_MANAGER);
    } else if (user.role === ROLE_DRONEOPERATOR) {
      nav("/" + DRONE_OPERATOR);
    } else if (user.role === ROLE_MAINTENANCEENGINEER) {
      nav("/" + CHURCH_MAINTENANCE_ENGINEER);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData({
      ...formData,
      cur_password: "",
      new_password: "",
      re_password: "",
    });
    setOpen(false);
  };

  const handleUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/auth/updateprofile`,
      "PUT",
      {
        email: currentUser.email,
        first_name: formData.first_name,
        last_name: formData.last_name,
        old_password: formData.cur_password,
        new_password: formData.new_password,
      },
      currentUser,
      logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          toast.success("Profile updated successfully");
          handleClose();
        } else {
          toast.error("Failed to update user profile");
        }
      })
      .catch((error) => {
        console.error(error);
        setErrors(["Failed to update user profile."]);
      });
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { logout } = useAuth();
  const handleLogout = () => {
    // Logout
    if (isDarkMode) {
      toggleTheme();
    }
    logout();
  };

  const validatePassword = (password: string) => {
    const errors = [];

    // Check password length
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }

    // Check uppercase letter
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }

    // Check lowercase letter
    if (!/[a-z]/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }

    // Check number
    if (!/\d/.test(password)) {
      errors.push("Password must contain at least one number.");
    }

    // Check special character
    if (!/[!@#$%^&*()]/.test(password)) {
      errors.push("Password must contain at least one special character.");
    }

    setPasswordErrors(errors);
    const strength = errors.length === 5 ? 1 : 5 - errors.length;
    setPasswordStrength(strength);
  };

  const validateOldPassword = (password: string) => {
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/auth/validatepassword`,
      "POST",
      {
        email: currentUser.email,
        password: password,
      },
      currentUser,
      logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          setErrors([]);
        } else {
          setErrors(["Incorrect current password."]);
        }
      })
      .catch((error) => {
        console.error(error);
        setErrors(["Incorrect current password."]);
      });
  };

  const handleOnChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });

    if (event.target.name === "new_password") {
      validatePassword(event.target.value);
    } else if (event.target.name === "re_password") {
      if (event.target.value !== formData.new_password) {
        setPasswordErrors(["Passwords do not match."]);
      } else {
        setPasswordErrors([]);
      }
    }
    if (event.target.name === "cur_password") {
      validateOldPassword(event.target.value);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <>
      <ToastContainer />
      <Dialog open={open} onClose={handleClose}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5">Edit Account</Typography>
          <Divider />
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              {errors.map((error) => (
                <Box
                  display="flex"
                  alignItems="center"
                  key={error}
                  pl={2}
                  pr={2}
                >
                  <Box
                    height="8px"
                    width="8px"
                    borderRadius="50%"
                    marginRight="5px"
                    sx={{
                      backgroundColor: "secondary.main",
                    }}
                  />
                  <Typography
                    variant="body2"
                    color="secondary"
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {error}
                  </Typography>
                </Box>
              ))}
            </Box>
            <form onSubmit={handleUpdate}>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2,
                  mb: 2,
                }}
              >
                <FormLabel>First Name</FormLabel>
                <TextField
                  variant="outlined"
                  value={formData["first_name"]}
                  name="first_name"
                  onChange={handleOnChange}
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2,
                  mb: 2,
                }}
              >
                <FormLabel>Last Name</FormLabel>
                <TextField
                  variant="outlined"
                  value={formData["last_name"]}
                  name="last_name"
                  onChange={handleOnChange}
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2,
                  mb: 2,
                }}
              >
                <FormLabel required>Current Password</FormLabel>
                <TextField
                  variant="outlined"
                  required
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={formData["cur_password"]}
                  name="cur_password"
                  onChange={handleOnChange}
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2,
                  mb: 2,
                }}
              >
                <FormLabel>New Password</FormLabel>
                <TextField
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={formData["new_password"]}
                  name="new_password"
                  onChange={handleOnChange}
                />
              </FormControl>
              {formData["new_password"] &&
                passwordErrors.map((error) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    key={error}
                    pl={2}
                    pr={2}
                  >
                    <Box
                      height="8px"
                      width="8px"
                      borderRadius="50%"
                      marginRight="5px"
                      sx={{
                        backgroundColor: "secondary.main",
                      }}
                    />
                    <Typography
                      variant="caption"
                      color="secondary"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      {error}
                    </Typography>
                  </Box>
                ))}

              {formData["new_password"] && (
                <PasswordStrength strength={passwordStrength} />
              )}
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2,
                  mb: 2,
                }}
              >
                <FormLabel>Confirm Password</FormLabel>
                <TextField
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={formData["re_password"]}
                  name="re_password"
                  onChange={handleOnChange}
                />
              </FormControl>
              <FormControl>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ mt: 2 }}
                  disabled={errors.length > 0 || passwordErrors.length > 0}
                >
                  Update Profile
                </Button>
              </FormControl>
            </form>
          </Box>
        </Box>
      </Dialog>
      <AppBar
        position="static"
        sx={{
          background: !isDarkMode
            ? "linear-gradient(to right, #ffffff, #0099ff)"
            : "linear-gradient(to right, #000000, #000000)",
        }}
      >
        <Box sx={{ ml: 1, mr: 1 }}>
          <Toolbar disableGutters>
            <Box
              sx={{
                display: "flex",
                width: "100vw",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(currentUser);
                }}
              >
                <Box
                  component="img"
                  src={logo}
                  alt="random"
                  sx={{
                    width: "50px",
                    height: "auto",
                    mr: 2,
                  }}
                />
                <Typography
                  variant="h4"
                  noWrap
                  sx={{
                    fontFamily: "monospace",
                    fontWeight: 900,
                    color: !isDarkMode ? "black" : "white",
                    textDecoration: "none",
                  }}
                >
                  {APP_NAME}
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }} />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 0,
                }}
              >
                <Typography
                  sx={{
                    mr: 1,
                    display: { xs: "none", md: "block" },
                    fontWeight: 500,
                    fontFamily: "monospace",
                  }}
                  variant="body1"
                >
                  {currentUser
                    ? currentUser.first_name + " " + currentUser.last_name
                    : ""}
                </Typography>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="prof pic"
                    src={currentUser ? currentUser.profile_picture : ""}
                  />
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key="email" disabled>
                    <Typography textAlign="center">
                      {currentUser ? currentUser.email : "abc@glazeai.com"}
                    </Typography>
                  </MenuItem>
                  <Divider />
                  {currentUser && (
                    <MenuItem key="settings" onClick={handleOpen}>
                      <ManageAccountsIcon sx={{ mr: 1 }} />
                      <Typography textAlign="center">Edit Account</Typography>
                    </MenuItem>
                  )}
                  <MenuItem key="logout" onClick={handleLogout}>
                    <LogoutIcon sx={{ mr: 1 }} />
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </>
  );
}
export default MainAppBar;
