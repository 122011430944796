import React from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Typography,
  Box,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Annotation } from "./ImgAnnotator";

interface AnnotationDialogBoxProps {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  currentAnnotation: Annotation | null;
  setCurrentAnnotation: React.Dispatch<React.SetStateAction<Annotation | null>>;
  annotations: Annotation[];
  setAnnotations: React.Dispatch<React.SetStateAction<Annotation[]>>;
}

export const AnnotationDialogBox: React.FC<AnnotationDialogBoxProps> = ({
  open,
  handleOpen,
  handleClose,
  currentAnnotation,
  setCurrentAnnotation,
  annotations,
  setAnnotations,
}) => {
  const handleRemove = () => {
    const selectedAnnotation = annotations.find(
      (annotation) => annotation.id === currentAnnotation?.id
    );
    if (selectedAnnotation) {
      const filteredAnnotations = annotations.filter(
        (annotation) => annotation.id !== selectedAnnotation.id
      );
      setAnnotations(filteredAnnotations);
    }
    handleClose();
  };

  const [confirmRemove, setConfirmRemove] = React.useState(false);

  return (
    <>
      <Dialog open={confirmRemove} onClose={() => setConfirmRemove(false)}>
        <DialogTitle>
          Are you sure you want to remove this annotation?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              handleOpen();
              setConfirmRemove(false);
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setConfirmRemove(false);
              handleRemove();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());

            if (!formJson.topic || !formJson.description)
              return alert("Please fill in all fields");
            if (currentAnnotation) {
              const selectedAnnotation = annotations.find(
                (annotation) => annotation.id === currentAnnotation.id
              );
              if (selectedAnnotation) {
                selectedAnnotation.topic = formJson.topic;
                selectedAnnotation.description = formJson.description;
                selectedAnnotation.color = formJson.color;
                setAnnotations([...annotations]);
              } else {
                currentAnnotation.topic = formJson.topic;
                currentAnnotation.description = formJson.description;
                setAnnotations([
                  ...annotations,
                  currentAnnotation as Annotation,
                ]);
              }
            }
            handleClose();
          },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              direction: "row",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "start",
            }}
          >
            <Typography variant="h6">
              Enter the topic and description
            </Typography>

            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                direction: "column",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                position: "absolute",
                right: 16,
                top: 16,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="topic"
            label="Topic"
            type="text"
            fullWidth
            variant="standard"
            value={currentAnnotation?.topic || ""}
            onChange={(e) => {
              const newAnnotation = { ...currentAnnotation };
              newAnnotation.topic = e.target.value;
              setCurrentAnnotation(newAnnotation as Annotation);
            }}
          />
          <TextField
            required
            margin="dense"
            id="description"
            multiline={true}
            name="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            value={currentAnnotation?.description || ""}
            onChange={(e) => {
              const newAnnotation = { ...currentAnnotation };
              newAnnotation.description = e.target.value;
              setCurrentAnnotation(newAnnotation as Annotation);
            }}
          />
          <input
            name="color"
            style={{ marginTop: "10px" }}
            type="color"
            value={currentAnnotation?.color || "#eb9234"}
            onChange={(e) => {
              const newAnnotation = { ...currentAnnotation };
              newAnnotation.color = e.target.value;
              setCurrentAnnotation(newAnnotation as Annotation);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setConfirmRemove(true);
              handleClose();
            }}
          >
            Remove
          </Button>
          <Button variant="contained" color="success" type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
