import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import logo from "../../../assets/images/logo.png";

const SectionOne = ({ width }: { width: number }) => {
  const waveLength = width / 5;

  // Define the keyframes for the pulse animation
  const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(64, 126, 215, 1);
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0px 20px ${waveLength}px rgba(64, 126, 215, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(64, 126, 215, 0);
  }
`;

  // Create a styled component that applies the pulse animation
  const PulseBox = styled(Box)`
    display: inline-block;
    animation: ${pulse} 2s infinite;
    background: linear-gradient(#ffffff, #407ed7);
  `;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <PulseBox
        sx={{
          width: { xs: 50, sm: 100, md: 200 },
          height: { xs: 50, sm: 100, md: 200 },
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          borderRadius: "50%",
          overflow: "hidden",
        }}
      >
        <img
          src={logo}
          alt={"LOGOPULSE"}
          style={{ width: "85%", height: "85%" }}
        />
      </PulseBox>
    </Box>
  );
};

export default SectionOne;
