import * as React from "react";
import { Typography, Popover } from "@mui/material";
import { Annotation } from "./ImgAnnotator";

interface AnnotationDescriptionProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  currentAnnotation: Annotation | null;
  mouseX: number;
  mouseY: number;
}
export const AnnotationDescription: React.FC<AnnotationDescriptionProps> = ({
  anchorEl,
  setAnchorEl,
  currentAnnotation,
  mouseX,
  mouseY,
}) => {
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: mouseY, left: mouseX }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="h5" sx={{ p: 1 }}>
          {currentAnnotation?.topic}
        </Typography>
        <Typography variant="body1" sx={{ p: 1 }}>
          {currentAnnotation?.description}
        </Typography>
      </Popover>
    </div>
  );
};
