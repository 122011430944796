import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Typography,
} from "@mui/material";
import React from "react";
import { ReviewRequest } from "../../../utils/interfaces";
import church_request from "../../../assets/images/church_req.webp";

interface ChildProps {
  church: ReviewRequest;
  setOpen: (arg0: boolean) => void;
  setCurrentChurch: (arg0: ReviewRequest) => void;
  setReviewRequestUpdated: (arg0: boolean) => void;
}

export const ReviewRequestCard: React.FC<ChildProps> = ({
  church,
  setOpen,
  setCurrentChurch,
  setReviewRequestUpdated,
}) => {
  const handleViewChurchDetails = () => {
    setReviewRequestUpdated(false);
    setOpen(true);
    setCurrentChurch(church);
  };

  return (
    <Card
      sx={{
        width: { xs: "80vw", sm: 500 },

        height: 185,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: { xs: 1, sm: 3 },
        }}
      >
        <Box
          component="img"
          src={church.images[0] ? church.images[0] : church_request}
          alt="random"
          sx={{
            width: 150,
            height: 150,
            objectFit: "cover",
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            variant="h5"
            color="text.primary"
            sx={{ fontWeight: 600 }}
          >
            {church.name}
          </Typography>
          <Typography
            variant="caption"
            color="text.primary"
            sx={{ fontWeight: 500 }}
          >
            Requested by: {church.manager}
          </Typography>
          <Chip
            sx={{ mt: 1 }}
            label={church.status}
            color="secondary"
            variant="outlined"
          />
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleViewChurchDetails}
            sx={{
              color: "white",
              borderRadius: "20px",
              width: "150px",
              mt: 2,
            }}
          >
            View Request
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ReviewRequestCard;
