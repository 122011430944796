import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Button } from "@mui/material";
import { MenuItem, TextField, Typography } from "@mui/material";
import { ROLE_BUSINESSMANAGER, ROLE_DRONEOPERATOR } from "../../constants";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

interface Inputs {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  role: string;
}

const schema = z.object({
  first_name: z.string().min(3),
  last_name: z.string().min(3),
  email: z.string().email(),
  role: z.enum([ROLE_BUSINESSMANAGER, ROLE_DRONEOPERATOR]),
});

interface EditManagerFormDialogProps {
  open: boolean;
  onClose: (
    manager: {
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      password: string;
      role: string;
    } | null
  ) => void;
  manager:
    | {
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        created_at: string;
        role: string;
      }
    | undefined;
}

const roles = [
  {
    value: ROLE_BUSINESSMANAGER,
    label: "Business Manager",
  },
  {
    value: ROLE_DRONEOPERATOR,
    label: "Drone Operator",
  },
];

export default function EditManagerFormDialog(
  props: EditManagerFormDialogProps
) {
  const { open, onClose } = props;

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(data);

    const first_name = data.first_name;
    const last_name = data.last_name;
    const email = data.email;
    const password = data.password;
    // const confirm_password = formData.get('confirm_password');
    const role = data.role;

    const manager = {
      id: props.manager?.id as string,
      first_name: first_name as string,
      last_name: last_name as string,
      email: email as string,
      password: password as string,
      role: role as string,
    };
    onClose(manager);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      first_name: props.manager?.first_name,
      last_name: props.manager?.last_name,
      email: props.manager?.email,
      role: props.manager?.role,
    },
  });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        onClose(null);
      }}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle variant="h4">Create New Manager</DialogTitle>
      <DialogContent>
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          First Name
        </Typography>
        <Controller
          name="first_name"
          control={control}
          defaultValue={props.manager?.first_name}
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              required
              margin="dense"
              id="first_name"
              type="text"
              fullWidth
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
            />
          )}
        />

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Last Name
        </Typography>

        <Controller
          name="last_name"
          control={control}
          defaultValue={props.manager?.last_name}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="last_name"
              type="text"
              fullWidth
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
            />
          )}
        />

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Email
        </Typography>

        <Controller
          name="email"
          control={control}
          defaultValue={props.manager?.email}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="email"
              type="email"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />

        {/* <TextField
          required
          margin="dense"
          id="email"
          name="email"
          // label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          defaultValue={props.manager?.email}
        /> */}
        {/* TODO: Need to add a toggle for changing password */}
        {/* <Typography variant="h6"  style={{ marginTop: '20px' }}>
              Password
            </Typography>
            <TextField
              required
              margin="dense"
              id="password"
              name="password"
              // label="Password"
              type="password"
              fullWidth
              variant="outlined"
            />
            <Typography variant="h6"  style={{ marginTop: '20px' }}>
              Confirm Password
            </Typography>
            <TextField
              required
              margin="dense"
              id="confirm_password"
              name="confirm_password"
              // label="Confirm Password"
              type="password"
              fullWidth
              variant="outlined"
            /> */}
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Role
        </Typography>

        <Controller
          name="role"
          control={control}
          defaultValue={props.manager?.role}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="role"
              select
              // label="Role"
              fullWidth
              variant="outlined"
            >
              {roles.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        {/* <TextField
          required
          margin="dense"
          id="role"
          select
          name="role"
          // label="Role"
          defaultValue={
            roles.find((role) => role.value === props.manager?.role)?.value
          }
          // helperText="Please select the role"
          fullWidth
          variant="outlined"
        >
          {roles.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField> */}
      </DialogContent>
      <DialogActions style={{ padding: "20px" }}>
        <Button
          onClick={() => {
            onClose(null);
          }}
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>
        <Button type="submit" variant="outlined" size="large">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
